import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SPORTS_URL } from "../../../queries/api";

function FilterBar({
  showActivityFilter = true,
  showTimeFilter = true,
  onActivitySelect,
  onTimeSelect,
  onRankingTypeChange,
  onFilterChange,
  rankingType,
}) {
  const location = useLocation();
  const [showActivityDropdown, setShowActivityDropdown] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('Wszystkie aktywności');
  const [selectedTimeRange, setSelectedTimeRange] = useState('Przedział czasowy');
  const [selectedSex, setSelectedSex] = useState('Dowolna płeć');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('Wszystkie grupy wiekowe');
  const [activityTypes, setActivityTypes] = useState([{ name: 'Wszystkie aktywności', id: null }]);

  const activityDropdownRef = useRef(null);
  const timeDropdownRef = useRef(null);
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        activityDropdownRef.current &&
        !activityDropdownRef.current.contains(event.target) &&
        showActivityDropdown
      ) {
        setShowActivityDropdown(false);
      }
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target) &&
        showTimeDropdown
      ) {
        setShowTimeDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showActivityDropdown, showTimeDropdown]);

  useEffect(() => {
    if (location.pathname.includes('/account/activities')) {
      const fetchActivityTypes = async () => {
        try {
          const response = await fetch(SPORTS_URL);
          const data = await response.json();
          setActivityTypes([{ name: 'Wszystkie aktywności', id: null }, ...data.results]);
        } catch (error) {
          console.error('Error fetching activity types:', error);
        }
      };
      fetchActivityTypes();
    }
  }, [location.pathname]);

  const handleActivitySelect = (activity) => {
    setSelectedActivity(activity.name);
    setShowActivityDropdown(false);
    if (onActivitySelect) onActivitySelect(activity.id);
  };

  const handleTimeSelect = (timeRange) => {
    setSelectedTimeRange(timeRange);
    setShowTimeDropdown(false);
    if (onTimeSelect) onTimeSelect(timeRange);
  };

  const handleRankingChange = () => {
    const newRankingType = rankingType === 'individual' ? 'cities' : 'individual';
    onRankingTypeChange(newRankingType);
  };

  const handleConfirmFilters = () => {
    onFilterChange({ sex: selectedSex, ageGroup: selectedAgeGroup });
    setShowFilterDropdown(false);
  };

  const handleCancelFilters = () => {
    setShowFilterDropdown(false);
  };

  const displayNameRankingType = rankingType === 'individual' ? 'Indywidualny' : 'Grupowy';

  return (
    <div className="flex items-center min-h-[88px] h-auto justify-center bg-white px-6 relative shadow-bottom-gradient z-20">
      {/* Przycisk Filtruj otwierający dropdown (Tylko dla rankingów) */}
        <div className="relative">
          <button
            className="flex items-center space-x-4 text-gray-600 text-lg font-normal cursor-pointer"
            onClick={() => setShowFilterDropdown(!showFilterDropdown)}
          >
            <span>Filtruj</span>
            <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_filter.svg`} alt="Filter Icon" className="w-6 h-6" />
          </button>

          {/* Dropdown z filtrami */}
          {showFilterDropdown && location.pathname.includes('/account/ranking') &&  (
            <div className="absolute top-12 left-1/2 transform -translate-x-1/2 mt-2 w-auto min-w-96 bg-white shadow-lg rounded-bl-2xl rounded-br-2xl border-none z-9999999">
              <div className='px-4'>
                <h3 className="text-lg font-semibold mb-2">Filtrowanie Płci</h3>
                {['Dowolna płeć', 'Mężczyzna', 'Kobieta'].map((sex) => (
                  <label key={sex} className="flex items-center mt-1">
                    <input className='accent-[#549C37]' type="radio" name="sex" checked={selectedSex === sex} onChange={() => setSelectedSex(sex)} />
                    <span className="ml-2">{sex}</span>
                  </label>
                ))}
              </div>

              <div className='px-4'>
                <h3 className="text-lg font-semibold mt-4 mb-2">Filtrowanie Grupy Wiekowej</h3>
                {['Wszystkie grupy wiekowe', 'Do 15 lat', 'Do 17 lat', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'].map((group) => (
                  <label key={group} className="flex items-center mt-1">
                    <input className='accent-[#549C37]' type="radio" name="ageGroup" checked={selectedAgeGroup === group} onChange={() => setSelectedAgeGroup(group)} />
                    <span className="ml-2">{group}</span>
                  </label>
                ))}
              </div>

              <div className="flex justify-between mt-4 w-full text-center overflow-hidden" style={{borderTop: "1px solid #016622"}}>
                <button
                  className="relative text-[#549C37] text-base  rounded-bl-2xl w-1/2 py-2 hover:font-semibold hover:bg-[#549C37] hover:text-white"
                >
                  <span className="absolute top-0 right-0 h-full" style={{borderRight: "1px solid #016622"}}></span>
                  Anuluj
                </button>
                <button className="text-[#549C37] text-base rounded-br-2xl w-1/2 py-2 font-semibold hover:bg-[#549C37] hover:text-white">
                  Potwierdź
                </button>
              </div>
            </div>
          )}
        </div>

      {/* Filtr Aktywności (Tylko dla aktywności) */}


        {showActivityFilter && location.pathname.includes('/account/activities') && (
          <div className="relative ml-8" ref={activityDropdownRef}>
            {/* Przycisk otwierający dropdown */}
            <button
              className="flex items-center text-lg font-normal border-none bg-white cursor-pointer"
              onClick={() => setShowActivityDropdown(!showActivityDropdown)}
            >
              {selectedActivity}
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_arrow_down.svg`} alt="Arrow Down Icon" className="px-2" />
            </button>

            {/* Lista wyboru */}
            {showActivityDropdown && (
              <ul className="absolute top-12 left-1/2 transform -translate-x-1/2 mt-2 w-auto min-w-60 bg-white shadow-lg rounded-bl-2xl rounded-br-2xl border-none z-20 mx-0 px-0">
                {activityTypes.map((activity) => (
                  <li
                    key={activity.id || 'all'}
                    className="w-auto py-2 px-6 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleActivitySelect(activity)}
                  >
                    {activity.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

      <div className="relative mx-5">
  {location.pathname.includes('/account/ranking') && (
    <button 
      className="flex items-center text-lg font-normal bg-white border border-gray-300 px-4 py-2 rounded-full cursor-pointer"
      onClick={handleRankingChange}
    >
      {displayNameRankingType}
      <img src={`${process.env.PUBLIC_URL}/images/user_account/toggle_arrows.svg`} alt="Toggle Icon" className="px-2" />
    </button>
  )}

  {/* Filtr czasu - widoczny tylko, jeśli showTimeFilter jest aktywny i nie jesteśmy w rankingu */}
  {showTimeFilter && !location.pathname.includes('/account/ranking') && (
    <div className="relative inline-block" ref={timeDropdownRef}>
      <button 
        className="flex items-center text-lg font-normal bg-white border-none cursor-pointer"
        onClick={() => setShowTimeDropdown(!showTimeDropdown)}
      >
        {selectedTimeRange}
        <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_arrow_down.svg`} alt="Arrow Down Icon" className="px-2" />
      </button>

      {/* Dropdown z filtrami czasu */}
      {showTimeDropdown && (
        <ul className="absolute top-12 left-1/2 transform -translate-x-1/2 mt-2 w-auto min-w-44 bg-white shadow-lg rounded-bl-2xl rounded-br-2xl border-none z-9999999 p-0">
                <li className="py-2 px-6 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('Dzisiaj')}>
                  Dzisiaj
                </li>
                <li className="py-2 px-6 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('W tym tygodniu')}>
                  W tym tygodniu
                </li>
                <li className="py-2 px-6 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('Ten miesiąc')}>
                  Ten miesiąc
                </li>
              </ul>
      )}
    </div>
  )}
</div>

    </div>
  );
}

export default FilterBar;
