import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useOutletContext, useParams } from 'react-router-dom'; 
import { FaCity } from 'react-icons/fa';
import apolloClient from '../../../queries/ApolloUser';
import { GET_CITY_RANKING } from '../../../queries/GraphQL_Query';

const BASE_URL = 'http://panel.aktywne.miasta.pl/media/';
const UserDefault = "/images/user_account/user_placeholder.svg";

const Ranking = () => {
  const { rankingType, setRankingType } = useOutletContext(); // Pobieramy rankingType oraz setRankingType
  const { competitionId } = useParams();
  const [rankingData, setRankingData] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(null);

  // ✅ Dynamiczny filtr na podstawie rankingType i selectedCityId
  const filter =
  rankingType === 'cities'
  ? { competition: { equalTo: competitionId },
  cityGrpName: { isNull: false },
  city: { isNull: true },
  grp: { isNull: true },
  subGrp: { isNull: true },
  usr: { isNull: true },
  age: { isNull: true },
  sex: { isNull: false }, // Ranking miast
}
  : rankingType === 'group'
  ? {
    competition: { equalTo: competitionId },
    city: { equalTo: selectedCityId },
    grp: { isNull: false },
    subGrp: { isNull: true },
    usr: { isNull: true },
    age: { isNull: true },
    sex: { isNull: false },
  } // Ranking grup w danym mieście
  : {
      competition: { equalTo: competitionId },
      cityGrpName: { isNull: true },
      city: { isNull: true },
      grp: { isNull: true },
      subGrp: { isNull: true },
      usr: { isNull: false },
      age: { isNull: true },
      sex: { isNull: false },
    }; // Ranking indywidualny}; // Ranking indywidualny

  const orderBy = rankingType === 'cities' ? ['POINTS_DESC'] : ['DISTANCE_DESC'];

  const { data, loading, error } = useQuery(GET_CITY_RANKING, {
    client: apolloClient,
    variables: { filter, orderBy, first: 100, offset: 0 },
  });

  useEffect(() => {
    if (data) {
      const newData = data.allFatAggs.nodes.map((node, index) => ({
        cityId: node.cityGrp,
        cityName: rankingType === 'cities'
          ? node.cityGrpName
          : rankingType === 'group' && selectedCityId
          ? node.grpName // Pobieramy nazwę grupy w mieście
          : `${node.usrFirstName} ${node.usrLastName}`,
      
        // ✅ Dynamiczny wybór zdjęcia
        cityImgUrl: rankingType === 'individual'
          ? (node.usrImage ? `${BASE_URL}${node.usrImage}` : UserDefault)  // Zdjęcie użytkownika
          : rankingType === 'group' && selectedCityId
          ? (node.grpImage ? `${BASE_URL}${node.grpImage}` : UserDefault) // Zdjęcie grupy w mieście
          : (node.cityGrpImage ? `${BASE_URL}${node.cityGrpImage}` : UserDefault), // Zdjęcie miasta
      
        distance: node.distance ? node.distance.toFixed(2) : '0',
        points: node.points ? node.points.toFixed(2) : '0',
        rank: index + 1,
      }));

      setRankingData(newData);
    }
  }, [data, rankingType, selectedCityId]);

  const handleCityClick = (cityId) => {
    setSelectedCityId(cityId);
    setRankingType('group');
  };

  const handleBackToCityRanking = () => {
    setSelectedCityId(null);
    setRankingType('cities');
  };

  if (loading) return <p>Ładowanie rankingu...</p>;
  if (error) return <p>Błąd ładowania danych: {error.message}</p>;

  return (
    <div className="p-6 bg-white mx-auto">
      <h2 className="text-center text-2xl font-bold text-green-700 mb-8">
        {selectedCityId ? `Ranking Grup w mieście: ${rankingData[0]?.cityName || 'Nieznane'}` : 'Ranking Miast'}
      </h2>

      {selectedCityId && (
        <button onClick={handleBackToCityRanking} className="px-4 py-2 bg-green-600 text-white rounded-lg mb-4">
          Powrót do rankingu miast
        </button>
      )}

      {/* <div className="flex justify-center border-b-2 border-gray-200 mb-8">
        <button
          onClick={() => handleBackToCityRanking()}
          className={`px-6 py-3 text-lg font-semibold ${rankingType === 'cities' ? 'border-b-4 border-green-600 text-green-600' : 'text-gray-500'}`}
        >
          Ranking Grupowy
        </button>
        <button
          onClick={() => setRankingType('individual')}
          className={`px-6 py-3 text-lg font-semibold ${rankingType === 'individual' ? 'border-b-4 border-green-600 text-green-600' : 'text-gray-500'}`}
        >
          Ranking Indywidualny
        </button>
      </div> */}

{/* Lista miast lub grup */}
<div className="flex justify-center items-end my-20">
        {rankingData.slice(0, 3).map((entry, index) => {
          const isFirst = index === 0;
          const positionStyle = isFirst ? 'order-2' : index === 1 ? 'order-1' : 'order-3';
          const svgSource = `${process.env.PUBLIC_URL}/images/user_account/${isFirst ? 'first' : index === 1 ? 'second' : 'third'}_place.svg`;
          const height = isFirst ? 327 : index === 1 ? 270 : 229;

          return (
            <div
              key={entry.rank}
              className={`flex flex-col items-center justify-end p-4 mx-2 ${positionStyle}`}
              style={{
                height: `${height}px`,
                width: '178px',
                backgroundImage: `url(${svgSource})`,
                position: 'relative',
                backgroundSize: 'cover'
              }}
            >
              <div
                className="flex items-center justify-center bg-white rounded-full shadow-custom mb-2"
                style={{
                  width: '120px',
                  height: '120px',
                  position: 'absolute',
                  top: '-35px',
                }}
              >
                {entry.cityImgUrl ? (
                    <img 
                    src={entry.cityImgUrl} 
                    alt={entry.cityName} 
                    className="object-cover rounded-full w-3/4 h-auto max-h-[100px]" 
                  />
                ) : rankingType === 'group' ? (
                  <FaCity size={24} className="text-green-600" />
                ) : (
                  <img 
                    src={`${process.env.PUBLIC_URL}/images/default-user.png`} 
                    alt="Domyślny użytkownik" 
                    className="object-cover rounded-full w-3/4 h-auto max-h-[100px]"
                  />
                )}
              </div>
              <p style={{marginTop: "100px", display: "block", top: "0"}} className="text-white font-semibold absolute">{entry.cityName}</p>
              <p style={{top: "30px"}} className="text-white font-bold text-7xl mt-24 absolute">{entry.rank}</p>
              <p className="text-white mt-2">
                {rankingType === 'group' ? `${entry.points} pkt` : `${entry.distance} km`}
              </p>
              {rankingType === 'group' && (
                <p className="text-white mt-1">{entry.distance} km</p>
              )}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-4">
        {rankingData.map((entry) => (
          <div key={entry.rank} className="flex items-center justify-between sm:flex-col md:flex-row shadow-custom rounded-3xl p-4 cursor-pointer" onClick={() => rankingType === 'cities' && handleCityClick(entry.cityId)}>
            <div className="flex items-center">
              <div className="p-2 mr-4 w-24 h-24 flex items-center justify-center overflow-hidden">
                {entry.cityImgUrl ? <img src={entry.cityImgUrl} alt={entry.cityName} className="object-contain max-w-full max-h-full" /> : <FaCity className="text-primary-dark text-4xl" />}
              </div>
              <div>
                <p className="font-semibold">{entry.cityName}</p>
              </div>
            </div>
            <div className="text-right">
              <p className="font-bold text-gray-800">{rankingType === 'group' ? `${entry.points} pkt` : `${entry.distance} km`}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ranking;
