import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import apolloClient from '../../../queries/ApolloUser';
import { useUserContext } from '../components/UserContext';
import { Link } from 'react-router-dom';
import sportIcons from '../components/Icons'; // Import ikon

const GET_COMPETITIONS = gql`
  query RywalizacjeNew($userId: BigInt!) {
    allActivitiesCompetitions {
      nodes {
        name
        id
        start
        end
        type
        kind
        isDisplayedUntil
        description
        activitiesPhotosByCompetitionId {
          nodes {
            image
          }
        }
        activitiesCompetitionSportsByCompetitionId {
          nodes {
            activitiesSportBySportId {
              id
              name
            }
          }
        }
        activitiesUserjoinedcompetitionsByCompetitionId(condition: { userId: $userId }) {
          nodes {
            competitionId
          }
        }
      }
    }
  }
`;

const GET_USER_COMPETITION_IDS = gql`
  query UserCompetitionByKind($userId: BigInt!, $competitionKind: String!, $greaterThanOrEqualTo: Datetime!) {
    allWhoJoinedCompetitions(
      condition: { usr: $userId, competitionKind: $competitionKind }
      filter: { competitionIsDisplayedUntil: { greaterThanOrEqualTo: $greaterThanOrEqualTo } }
    ) {
      nodes {
        competition
      }
    }
  }
`;

const CompetitionsView = () => {
  const [activeTab, setActiveTab] = useState('public');
  const [publicCompetitions, setPublicCompetitions] = useState(null);
  const [privateCompetitions, setPrivateCompetitions] = useState(null);
  const { userId } = useUserContext();
  const todayDate = new Date().toISOString().slice(0, 10);
  const BASE_URL = 'http://panel.aktywne.miasta.pl/media/';

  // Pobieramy dane TYLKO raz
  const { loading: loadingCompetitions, error: errorCompetitions, data: dataCompetitions } = useQuery(GET_COMPETITIONS, {
    client: apolloClient,
    variables: { userId },
    fetchPolicy: 'cache-first', // 🔥 Nie pobiera ponownie, jeśli dane są już w cache
    skip: !!publicCompetitions, // 🔥 Zapobiega ponownemu pobieraniu
  });

  const { loading: loadingUserCompetitions, error: errorUserCompetitions, data: dataUserCompetitions } = useQuery(GET_USER_COMPETITION_IDS, {
    client: apolloClient,
    variables: {
      userId,
      competitionKind: 'user',
      greaterThanOrEqualTo: todayDate,
    },
    fetchPolicy: 'cache-first',
    skip: !!privateCompetitions,
  });

  useEffect(() => {
    if (dataCompetitions && publicCompetitions === null) {
      const filteredPublicCompetitions = dataCompetitions.allActivitiesCompetitions.nodes.filter(
        (comp) =>
          ["plain", "closed", "city"].includes(comp.kind) &&
          (!comp.isDisplayedUntil || comp.isDisplayedUntil >= todayDate)
      );
      setPublicCompetitions(filteredPublicCompetitions);
    }
  }, [dataCompetitions, publicCompetitions]);

  useEffect(() => {
    if (dataCompetitions && dataUserCompetitions && privateCompetitions === null) {
      const userCompetitionIds = new Set(
        dataUserCompetitions.allWhoJoinedCompetitions.nodes.map((node) => node.competition)
      );

      const filteredPrivateCompetitions = dataCompetitions.allActivitiesCompetitions.nodes.filter((comp) =>
        userCompetitionIds.has(comp.id.toString())
      );

      setPrivateCompetitions(filteredPrivateCompetitions);
    }
  }, [dataCompetitions, dataUserCompetitions, privateCompetitions]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const competitions = activeTab === 'public' ? publicCompetitions : privateCompetitions;

  if (loadingCompetitions || loadingUserCompetitions) return <p>Ładowanie danych...</p>;
  if (errorCompetitions || errorUserCompetitions) return <p>Błąd: {(errorCompetitions || errorUserCompetitions).message}</p>;

  return (
    <div className="p-4">
      <h1 className="text-green-600 text-2xl font-bold">RYWALIZACJE</h1>
      <div className="flex justify-between mt-4 mb-6">
        <button
          className={`w-1/2 py-4 text-xl rounded-full shadow-custom font-bold border-none ${
            activeTab === 'public' ? 'bg-btn_primary text-white' : 'bg-white text-[#454545]'
          }`}
          onClick={() => handleTabChange('public')}
        >
          Publiczne
        </button>
        <button
          className={`w-1/2 py-2 text-xl rounded-full ml-2 shadow-custom font-bold border-none ${
            activeTab === 'user' ? 'bg-btn_primary text-white' : 'bg-white text-[#454545]'
          }`}
          onClick={() => handleTabChange('user')}
        >
          Prywatne
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {competitions &&
          competitions.map((competition) => {
            const endDate = new Date(competition.end);
            const currentDate = new Date();
            const daysLeft = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));

            // Tworzymy ikony na podstawie sportów przypisanych do zawodów
            const sportIconsList = competition.activitiesCompetitionSportsByCompetitionId.nodes.map((sport, index) => {
              const sportId = sport.activitiesSportBySportId.id;
              return (
                <div key={index} className="flex items-center justify-center space-x-4 bg-white w-8 h-8">
                  <img
                    src={sportIcons[sportId] || '/images/user_account/default.png'} // Jeśli nie ma ikony, użyj domyślnej
                    alt={sport.activitiesSportBySportId.name}
                    className="w-[48px] px-4"
                  />
                </div>
              );
            });

            const hasJoined = competition.activitiesUserjoinedcompetitionsByCompetitionId.nodes.length > 0;

            return (
              <div key={competition.id} className="relative bg-white rounded-xl shadow-custom flex overflow-hidden min-h-[220px]">
                <div className="w-1/3">
                  <img
                    src={
                      competition.activitiesPhotosByCompetitionId.nodes[0]?.image
                        ? `${BASE_URL}${competition.activitiesPhotosByCompetitionId.nodes[0].image}`
                        : '/images/user_account/default.png'
                    }
                    alt={competition.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="w-2/3 p-4 flex flex-col justify-between">
                  <Link to={`/account/competitions/${competition.id}`} className="text-xl font-semibold text-[#454545]">
                    {competition.name}
                  </Link>
                  <p className="text-gray-600 mt-2">Suma kilometrów</p>
                  <p className="text-gray-600 mt-1">Do końca: {daysLeft} dni</p>
                  <div className='flex justify-between'>
                    <div className="flex items-center mt-2 space-x-4">{sportIconsList}</div>
                    <div>
                    {!hasJoined && activeTab === 'public' && (
                      <button className="bg-gradient-to-t from-[#003C10] to-[#72BF44] text-white py-4 px-12 rounded-full mt-2">
                        DOŁĄCZ
                      </button>
                    )}
                    </div>
                  </div>
                </div>
                {hasJoined && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/user_account/label_joined.png`}
                  alt="Joined"
                  className="absolute bottom-0 right-0 w-30"
                />
              )}
                
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CompetitionsView;
