import React from "react";
import "./AktywneSponsorsSection.css";
import Icon1 from "../assets/icons/sponsors/city.png";
import Icon2 from "../assets/icons/sponsors/onet.png";
import Icon3 from "../assets/icons/sponsors/zmp.png";
import Icon4 from "../assets/icons/sponsors/sisu1.png";
import Icon5 from "../assets/icons/sponsors/metropolie.png";
import Icon6 from "../assets/icons/sponsors/eska.png";
import Icon7 from "../assets/icons/sponsors/ibombo.png";
import Icon8 from "../assets/icons/sponsors/medonet.png";
import Icon9 from "../assets/icons/sponsors/noizz.png";
import Icon10 from "../assets/icons/sponsors/ecocleanHome.png";
import Icon11 from "../assets/icons/sponsors/peterson_tacx.png";
import Icon12 from "../assets/icons/sponsors/inoni.png";
import Icon13 from "../assets/icons/sponsors/mAG.png";
import Icon14 from "../assets/icons/sponsors/eska_rock.png";
import IconSponsor1 from "../assets/icons/sponsors/centrum.png";
import IconSponsor2 from "../assets/icons/sponsors/fale.png";
import CustomSlider from "./features/CustomSlider";
import '../i18n';
import { useTranslation } from 'react-i18next';


function IconColumn({ link, imageSource, altText, isStrategic }) {
    const containerClass = isStrategic ? "column-strategiczny" : "column";

    return (
        <div className={containerClass}>
            <div className="icon">
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src={imageSource} alt={altText} />
                </a>
            </div>
        </div>
    );
}

function SponsorSection({ sponsors, sectionTitle, isStrategic }) {
    return (
        <>
            <h1>{sectionTitle}</h1>
            <div className="columns-container-strategiczny">
                {sponsors.map((sponsor, index) => (
                    <IconColumn key={index} {...sponsor} isStrategic={isStrategic} />
                ))}
            </div>
        </>
    );
}

function AktywneSponsorsSection() {
    const { t } = useTranslation();
    const strategicSponsors = [
        { link: "https://centrumrowerowe.pl/", imageSource: IconSponsor1, altText: "Icon 1", isStrategic: true },
        { link: "https://falelokikoki.pl/", imageSource: IconSponsor2, altText: "Icon 2", isStrategic: true },
    ];

    const partners = [
        { link: "https://www.city-hotel.pl", imageSource: Icon1, altText: "Icon 1", isStrategic: false },
        // { link: "https://www.onet.pl", imageSource: Icon2, altText: "Icon 2", isStrategic: false },
        { link: "https://www.miasta.pl/", imageSource: Icon3, altText: "Icon 3", isStrategic: false },
        // { link: "https://sisusport.com", imageSource: Icon4, altText: "Icon 4", isStrategic: false },
        { link: "https://www.metropoliabydgoszcz.pl", imageSource: Icon5, altText: "Icon 5", isStrategic: false },
        { link: "https://www.eska.pl", imageSource: Icon6, altText: "Icon 6", isStrategic: false },
        { link: "https://www.ibombo.com", imageSource: Icon7, altText: "Icon 7", isStrategic: false },
        // { link: "https://www.medonet.pl", imageSource: Icon8, altText: "Icon 8", isStrategic: false },
        // { link: "https://noizz.pl", imageSource: Icon9, altText: "Icon 9", isStrategic: false },
        // { link: "https://www.facebook.com/people/Ecoclean-bikecom/61559956842976/", imageSource: Icon10, altText: "Icon 10", isStrategic: false },
        // { link: "https://www.bidonypro.pl", imageSource: Icon11, altText: "Icon 11", isStrategic: false },
        { link: "https://inoni.cc/", imageSource: Icon12, altText: "Icon 12", isStrategic: false, class: "inoni" },
        { link: "https://magterapia.pl/", imageSource: Icon13, altText: "Icon 13", isStrategic: false, class: "mag" },
        { link: "https://www.eskarock.pl/", imageSource: Icon14, altText: "Icon 14", isStrategic: false, class: "eska" },
    ];

    return (
        <div className="aktywne-sponsors-section">
          <SponsorSection sponsors={strategicSponsors} sectionTitle={t('sponsors_section.SPONSOR_STRATEGICZNY')} isStrategic={true} />
          <h1>{t('sponsors_section.PARTNERZY')}</h1>
          <div className="columns-container">
            <CustomSlider>
                {partners.map(partner => (
                <IconColumn key={partner.link} {...partner} isStrategic={false} />
                ))}
            </CustomSlider>
            </div>
        </div>
      );
}

export default AktywneSponsorsSection;