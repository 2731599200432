import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import FilterBar from './components/FilterBar';

function UserAccountPage() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedSportId, setSelectedSportId] = useState(null);
  const [rankingType, setRankingType] = useState('cities'); // Domyślnie "Ranking Miast"
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

    // const hiddenRoutesForFilterBar = ['/competitions', '/ranking'];
    const shouldShowFilterBar = !(
      location.pathname.startsWith('/account/competitions')
    );

    useEffect(() => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        navigate('/account/login', { replace: true });
      }
    }, []);

  return (
    <div className='account'>
      <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar className="account" isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Main content area */}
      <div className="flex flex-col flex-grow ml-0">
        {/* Header */}
        <header className="h-[88px] min-h-[88px] max-h-[88px] bg-gradient-to-r from-[#72BF44] to-[#003C10] shadow-lg flex items-center px-6 z-50">
          <button className="lg:hidden text-white focus:outline-none" onClick={toggleSidebar}>
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </header>

        {/* Filter Bar - przekazujemy rankingType oraz setRankingType */}
        {shouldShowFilterBar && <FilterBar 
          onActivitySelect={setSelectedSportId} 
          rankingType={rankingType} 
          onRankingTypeChange={setRankingType} 
        />}

        {/* Main content area - przekazujemy rankingType do Outlet */}
        <main className="flex-grow overflow-y-auto">
          <div className="container mx-auto px-4 py-6">
            <Outlet context={{ selectedSportId, rankingType, setRankingType }} />
          </div>
        </main>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black opacity-50 lg:hidden z-40" onClick={toggleSidebar}></div>
      )}
    </div>
    </div>
  );
}

export default UserAccountPage;
