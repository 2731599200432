import React from 'react';
import './NewsSection.css';
import { useNavigate } from 'react-router-dom';
import '../i18n';
import { useTranslation } from 'react-i18next';
 
function getShortDescription(description) {
    const dom = new DOMParser().parseFromString(description, 'text/html');
    const textContent = dom.body.textContent || "";
    const sentences = textContent.match(/[^.!?]+[.!?]*/g) || [];
    const shortDescription = sentences.slice(0, 5).join(" ");
    return shortDescription;
}

function formatDate(isoDate) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(isoDate).toLocaleDateString('pl-PL', options);
    return formattedDate;
}

function NewsItem({ id, name, date, description, pageEntryPhotosByEntryId, pageEntryAttachmentsByEntryId }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const baseUrl = "https://aktywne.miasta.pl/media/";

    const mainImage = pageEntryPhotosByEntryId.nodes.find(node => node.pagePhotoByPhotoId.mainImage);
    const imageUrl = mainImage ? baseUrl + mainImage.pagePhotoByPhotoId.file : null;

    const handleClick = () => {
        navigate(`/news/${id}`, {
            state: {
                name,
                date,
                description,
                imageUrl: imageUrl,
                attachments: pageEntryAttachmentsByEntryId.nodes
            }
        });
    }

    const shortDescription = getShortDescription(description);

    return (
        <div className="news-item">
            <img src={imageUrl} alt={name} />
            <p className="date">{formatDate(date)}</p>
            <h3>{name}</h3>
            <p className='news-desc'>{shortDescription}</p>
            <div className="news-item-button-container">
                <button onClick={handleClick}>{t('news_section_item.WIĘCEJ')} &gt;</button>
            </div>
        </div>
    );
}

export default NewsItem;
