import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../components/UserContext';
import { SPORTS_URL, CITIES_URL, BASE_IMAGE_URL } from "../../../queries/api";

const UserDefault = "/images/user_account/user_placeholder.svg";

const RegistrationForm = () => {
  const sex = '';
  const [userData, setUserData] = useState({
    userId: '',
    first_name: '',
    last_name: '',
    login: '',
    email: '',
    height: '',
    weight: '',
    date_of_birth: '',
    gender: sex === '0' ? 'Kobieta' : 'Mężczyzna',
    city_id: '',
    sports: {},
    acceptTerms: false,
    allowMarketing: false,
    public: 1,
    show_my_location_to_friends: 0,
    password: '',
    confirmPassword: '',
    registration_id: 'xxx',
  });

  const [isCityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [nextCitiesUrl, setNextCitiesUrl] = useState(CITIES_URL);
  const [sports, setSports] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingSports, setLoadingSports] = useState(true);
  const [cityError, setCityError] = useState(null);
  const [sportsError, setSportsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingMoreCities, setLoadingMoreCities] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userData);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(UserDefault);
  const [hasMoreCities, setHasMoreCities] = useState(true);
  const cityListRef = useRef(null);

  useEffect(() => {
    fetchSports();
    fetchCities();
  }, []); 

  const fetchCities = async (url = CITIES_URL) => {
    if (!url || loadingCities) return;

    setLoadingCities(true);
    try {
        console.log("Fetching cities from:", url); // Debug

        const response = await fetch(url);
        if (!response.ok) {
          const text = await response.text();
          console.error("Fetch cities failed. Status:", response.status, text);
          throw new Error(`HTTP Error: ${response.status}`);
        }
        const data = await response.json();
        
        console.log("Fetched cities:", data.results);

        setCities(prevCities => {
            // Usuwamy duplikaty na podstawie ID
            const uniqueCities = [...prevCities, ...data.results].reduce((acc, city) => {
                if (!acc.some(existingCity => existingCity.id === city.id)) {
                    acc.push(city);
                }
                return acc;
            }, []);

            return uniqueCities;
        });

        // Aktualizujemy link do kolejnej strony
        setNextCitiesUrl(data.next ? data.next.replace(/^http:/, 'https:') : null);

    } catch (error) {
        console.error("Błąd pobierania miast:", error);
    } finally {
        setLoadingCities(false);
    }
};


const handleScroll = () => {
  if (cityListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = cityListRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 20 && nextCitiesUrl) {
          fetchCities(nextCitiesUrl);
      }
  }
};



  const handleCityDropdownOpen = () => {
    setCityDropdownOpen(!isCityDropdownOpen);
  };

  const handleCityChange = (city) => {
    setUserData(prevData => ({
      ...prevData,
      city_id: city.id,
      city: city.name, // Ustawienie nazwy miasta po wyborze
    }));
    setCityDropdownOpen(false);
  };

  const fetchSports = async () => {
    setLoadingSports(true);
    try {
      const response = await fetch(SPORTS_URL);
      if (!response.ok) throw new Error('Error fetching sports');
      const data = await response.json();
      console.log(response, 'sports');
      setSports(data.results);
    } catch (error) {
      setSportsError('Could not load sports');
    } finally {
      setLoadingSports(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Usuwa błąd po uzupełnieniu pola
    }));
  };

  const handleGenderChange = (gender) => {
    setUserData((prevData) => ({
      ...prevData,
      gender,
    }));
    setSelectedGender(gender);
  };

  const handleSportsChange = (e) => {
    const { name, checked } = e.target;
    setUserData(prevData => ({
      ...prevData,
      sports: { ...prevData.sports, [name]: checked },
    }));
  };

  useEffect(() => {
    if (selectedImage) {
      setPreview(URL.createObjectURL(selectedImage)); // Tworzy podgląd wybranego pliku
    } else {
      setPreview(UserDefault); // Jeśli nie ma zdjęcia, ustaw domyślne
    }
  }, [selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const validateForm = () => {
    let newErrors = {};
    
    if (!userData.first_name.trim()) newErrors.first_name = "Podaj imię";
    if (!userData.last_name.trim()) newErrors.last_name = "Podaj nazwisko";
    if (!userData.login.trim()) newErrors.login = "Podaj login";
    if (!userData.email.trim()) newErrors.email = "Podaj adres e-mail";
    if (!userData.password.trim()) newErrors.password = "Podaj hasło";
    if (userData.password !== userData.confirmPassword) newErrors.confirmPassword = "Hasła się nie zgadzają";
    if (!userData.date_of_birth.trim()) newErrors.date_of_birth = "Podaj datę urodzenia";
    if (!userData.height.trim()) newErrors.height = "Podaj wzrost";
    if (!userData.weight.trim()) newErrors.weight = "Podaj wagę";

    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0; // Zwraca `true`, jeśli brak błędów
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');
    setSuccessMessage('');

    if (userData.password !== userData.confirmPassword) {
      setErrors('Passwords do not match');
      return;
    }

    if (!validateForm()) return; // Przerywa, jeśli są błędy

    try {
      const formData = new FormData();
      if (selectedImage) {
        formData.append('profile_image', selectedImage);
      }
      formData.append('first_name', userData.first_name);
      formData.append('last_name', userData.last_name);
      formData.append('login', userData.login);
      formData.append('email', userData.email);
      formData.append('pass', userData.password);
      formData.append('pass2', userData.confirmPassword);
      formData.append('weight', parseInt(userData.weight, 10));
      formData.append('height', parseInt(userData.height, 10));
      formData.append('date_of_birth', userData.date_of_birth);
      formData.append('sex', userData.sex);
      formData.append('preferred_sports', Object.keys(userData.sports).filter(key => userData.sports[key]).join(','));
      formData.append('registration_id', userData.registration_id);
      formData.append('public', userData.public ? 1 : 0);
      formData.append('full_version', 1);
      formData.append('city_id', parseInt(userData.city_id, 10));
      formData.append('show_my_location_to_friends', userData.show_my_location_to_friends);
      formData.append('log_platform', 'web');
      formData.append('log_app_version', '1.0');
      formData.append('log_device_manufacturer', 'unknown');
      formData.append('log_device_model', 'unknown');
      formData.append('log_language_code', 'pl');

      const response = await fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/user_register', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok && result.result === 'success') {
        setSuccessMessage('Registration successful!');
      } else {
        setErrorMessage(result.mess?.[0] || 'Registration failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during registration');
      console.error(error);
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  return (
       <div className="p-6 flex relative flex-col justify-center items-center">
      <div className="w-3/5 pr-8 box-border">
        <h2 className="text-2xl font-bold mb-4 pb-10 text-primary-dark uppercase text-center">Rejestracja użytkownika</h2>
        <div className="flex flex-col items-center justify-center w-full relative">
          {/* Podgląd obrazka - domyślnie UserDefault */}
          <img
            src={preview}
            alt="User"
            className="w-28 h-28 rounded-full object-cover border border-gray-300"
          />

          {/* Przycisk do dodawania zdjęcia */}
          <label className="text-secondary p-4 cursor-pointer transition">
            <input type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
            Dodaj zdjęcie
          </label>
        </div>

        <form className="space-y-4 w-full p-0 m-0" onSubmit={handleSubmit}>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Imię</label>
            <input 
              type="text" 
              name="first_name" 
              value={userData.first_name} 
              onChange={handleChange} 
              className={`border p-2 rounded-3xl ${errors.first_name ? "border-red-500" : ""}`}
            />
            {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Nazwisko</label>
            <input 
              type="text" 
              name="last_name" 
              value={userData.last_name} 
              onChange={handleChange} 
              className={`border p-2 rounded-3xl ${errors.last_name ? "border-red-500" : ""}`}
            />
            {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Login</label> 
            <input 
                type="text" 
                name="login" 
                value={userData.login} 
                // onChange={handleChange} 
                className={`border p-2 rounded-3xl ${errors.login ? "border-red-500" : ""}`}
              />
            {errors.login && <p className="text-red-500 text-sm">{errors.login}</p>}</div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">E-mail</label>
            <input 
              type="email" 
              name="email" 
              value={userData.email} 
              onChange={handleChange} 
              className={`border p-2 rounded-3xl ${errors.email ? "border-red-500" : ""}`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Hasło</label>
            <input 
              type="password" 
              name="password" 
              value={userData.password} 
              onChange={handleChange} 
              className={`border p-2 rounded-3xl ${errors.password ? "border-red-500" : ""}`}
            />
            {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Powtórz hasło</label>
            <input 
              type="password" 
              name="confirmPassword" 
              value={userData.confirmPassword} 
              onChange={handleChange} 
              className={`border p-2 rounded-3xl ${errors.confirmPassword ? "border-red-500" : ""}`}
            />
            {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
          </div>
          <div className='flex flex-col'>
            <p className='text-xs text-[#B7B7B7] w-full pl-3'>
            Wzrost, waga, data urodzenia i płeć są niezbędne do prawidłowego obliczenia spalanych przez ciebie kalorii. Prosimy o ich podanie, aby aplikacja mogła działać prawidłowo.
            </p>
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Wzrost</label>
            <input
              type="number"
              name="height"
              value={userData.height}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
              required 
            />
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Waga</label>
            <input
              type="number"
              name="weight"
              value={userData.weight}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
              required 
            />
          </div>
          <div className='flex flex-col'><label className="pl-2 inline-block pb-2 text-secondary font-semibold">Data urodzenia</label><input type="date" name="date_of_birth" value={userData.date_of_birth} onChange={handleChange} className="border rounded-full px-4 py-2" required /></div>

          <p className="text-sm text-gray-500 mb-4">
            Rekomendujemy wybranie swojego miasta.
          </p>
          <div className="mb-6">
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Miasto</label>
            <button
              type="button"
              onClick={handleCityDropdownOpen}
              className="w-full border p-2 rounded-3xl text-center bg-gradient-to-r from-[#72BF44] to-[#003C10] text-white h-10 flex items-center justify-center"
            >
              <span className="ml-2">&#9660;</span>
              {userData.city_id ? cities.find(city => city.id === parseInt(userData.city_id, 10))?.name : 'Wybierz miasto'}
            </button>
            {isCityDropdownOpen && (
            <ul
              ref={cityListRef}
              onScroll={handleScroll}
              className="absolute left-0 right-0 mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto z-10"
            >
              {loadingCities && cities.length === 0 ? (
                <li className="px-4 py-2 text-gray-500">Ładowanie...</li>
              ) : (
                cities.map((city) => (
                  <li key={city.id} className="px-4 py-2 cursor-pointer hover:bg-gray-200" onClick={() => handleCityChange(city)}>
                    {city.name}
                  </li>
                ))
              )}
              {loadingCities && <li className="px-4 py-2 text-gray-500">Ładowanie kolejnych miast...</li>}
            </ul>
          )}
          </div>

          {/* Płeć */}
          <div className="">
            <label className="block text-secondary font-semibold mb-2">Płeć</label>
            <div className="flex space-x-4">
            <button
                className={`px-4 py-2 rounded-full border transition-colors duration-300 ${
                  userData.gender === 'Kobieta' ? 'bg-[#72BF44] text-white' : 'bg-white shadow-custom text-gray-700' }`}
                onClick={(e) => { e.preventDefault(); handleGenderChange('Kobieta'); }}
              >
                Kobieta
              </button>
              <button
                className={`px-4 py-2 rounded-full border transition-colors duration-300 ${
                  selectedGender === 'Mężczyzna' ? 'bg-[#72BF44] text-white' : 'bg-white shadow-custom text-gray-700'
                }`}
                onClick={(e) => { e.preventDefault(); handleGenderChange('Mężczyzna'); }}
              >
                Mężczyzna
              </button>
            </div>
          </div>
        </form>
      </div>

            {/* Right Side - Sports Interests */}
      <div className="pt-4 w-3/5">
        <p className="text-sm text-gray-500 mb-4 inline-block">
          Wybierz sport, który Cię interesuje, aby otrzymywać informacje o rywalizacjach, konkursach oraz grach miejskich dostępnych dla danej kategorii.
        </p>
        {/* Sports Grid */}
        <div className="grid grid-cols-3 gap-4 mb-6 mt-6">
        {loadingSports ? (
          <p className="text-gray-500">Ładowanie sportów...</p>
        ) : sportsError ? (
          <p className="text-red-500">{sportsError}</p>
        ) : (
          sports.map((sport) => (
            <label key={sport.id} className="flex items-center cursor-pointer">
                <input type="checkbox" name={sport.id} checked={userData.sports[sport.id] || false} onChange={handleSportsChange} className="hidden"/>
                <span className={`w-6 h-6 mr-4 shadow-custom rounded flex items-center justify-center ${userData.sports[sport.id] ? 'bg-[#72BF44]' : 'bg-white'}`}>
                  {userData.sports[sport.id] && <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a 1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" /></svg>}
                </span>
                {sport.name}
          </label>
        ))
        )}
        </div>
        {/* Toggle Options */}
        <div className="space-y-4">
        {/* Accept Terms */}
        <div className="flex items-center">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={userData.acceptTerms}
              onChange={handleToggleChange}
              className="sr-only peer"
            />
            <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
            <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
          </label>
          <span>Akceptuję regulamin</span>
          </div>

              {/* Accept Competition Terms */}
              <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowMarketing"
                checked={userData.allowMarketing}
                onChange={handleToggleChange}
                className="sr-only peer"
                />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
                <span>Akceptuję regulamin rywalizacji</span>
          </div>
                  <span className='inline-block text-sm text-[#B7B7B7]'>Zaznacz tę opcję, jeśli chcesz aby Twoje imię i nazwisko było widoczne w rankingu rywalizacji i wyszukiwarce znajomych. Odznacz tę opcję, jeśli chcesz, aby Twoje dan były  ukryte dla wszystkich użytkowników aplikacji.</span>

          {/* Allow Display of Personal Data */}
          <div className="flex items-center justify-between">
            <span>Zezwalam na wyświetlanie moich danych osobowych innym użytkownikom</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#1F5F1E] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
          </div>

          {/* Allow Friends to See Location */}
          <div className="flex items-center justify-between">
            <span>Pozwól znajomym widzieć moją lokalizację</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#1F5F1E] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 w-4/5 mx-auto space-y-2">
          <button type='submit' onClick={handleSubmit} className="bg-[#1F5F1E] text-white py-3 px-4 rounded-full w-full hover:bg-primary-green transition-colors">
           Zarejestruj
          </button>
        </div>

        <p className="text-center mt-6 text-sm text-gray-600">
           Masz już konto?{' '}
            <Link to="/account/login" className="text-[#016623] font-semibold hover:underline">
              Zaloguj się
            </Link>
          </p>
      </div>
    </div>
  );
};

export default RegistrationForm;
