import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import apolloClient from "../../../queries/ApolloUser";
import { useUserContext } from "../components/UserContext";
import { GET_BADGES_LIST, GET_BADGES_DETAILS } from "../../../queries/GraphQL_Query";

const badgeIcon = "/images/user_account/badge.svg";
const badgeGoldIcon = "/images/user_account/badge_gold.svg";

const Badges = () => {
  const { userId, cityId } = useUserContext();
  const [selectedBadge, setSelectedBadge] = useState(null);
  const todayDate = new Date().toISOString().split("T")[0];
  
  // Pobranie listy odznak użytkownika i miasta
  const { loading, error, data } = useQuery(GET_BADGES_LIST, {
    client: apolloClient,
    variables: { cityIds: [cityId], userId, filter: {
        and: [
          {
            or: [
              { dateFrom: { lessThanOrEqualTo: todayDate } },
              { dateFrom: { isNull: true } }
            ]
          },
          {
            or: [
              { dateTo: { greaterThanOrEqualTo: todayDate } },
              { dateTo: { isNull: true } }
            ]
          }
        ]
      }
    },
    fetchPolicy: "cache-first",
  });

  // Pobranie szczegółów odznaki po kliknięciu
  const [fetchBadgeDetails, { loading: detailsLoading, data: badgeDetails }] = useLazyQuery(GET_BADGES_DETAILS, {
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const handleBadgeClick = (badgeId) => {
    setSelectedBadge(badgeId);
    fetchBadgeDetails({ variables: { badgeId } });
  };

  if (loading) return <p className="text-center text-gray-500">Ładowanie...</p>;
  if (error) return <p className="text-center text-red-500">Błąd: {error.message}</p>;

  // 1️⃣ Zdobyte przez użytkownika
  const userBadges = data?.allAccountsUserbadges?.nodes || [];
  const achievedBadgeIds = new Set(userBadges.map((b) => b.badgeId));

  // 2️⃣ Odznaki dostępne w mieście
  const cityBadges = data?.allActivitiesBadgeCities?.nodes || [];
  const cityBadgeIds = new Set(cityBadges.map((b) => b.badgeId));

  // 3️⃣ Wszystkie możliwe odznaki (powinny zawierać niezdobyte odznaki)
  const allBadges = data?.allActivitiesBadges?.nodes || [];

  // 4️⃣ Tworzenie mapy odznak, aby uniknąć duplikatów
  const badgeMap = new Map();

  // ✅ Dodajemy zdobyte przez użytkownika
  userBadges.forEach((badge) => {
    badgeMap.set(badge.badgeId, {
      id: badge.badgeId,
      name: badge.activitiesBadgeByBadgeId?.name || "Brak nazwy",
      description: badge.activitiesBadgeByBadgeId?.description || "Brak opisu",
      isAchieved: true,
    });
  });

  // ✅ Dodajemy wszystkie odznaki dostępne w mieście jako **niezdobyte**, jeśli nie są w `userBadges`
  cityBadges.forEach((badge) => {
    if (!badgeMap.has(badge.badgeId)) {
      badgeMap.set(badge.badgeId, {
        id: badge.badgeId,
        name: badge.activitiesBadgeByBadgeId?.name || "Brak nazwy",
        description: badge.activitiesBadgeByBadgeId?.description || "Brak opisu",
        isAchieved: false,
      });
    }
  });

  // ✅ Dodajemy wszystkie możliwe odznaki jako **niezdobyte**, jeśli nie są zdobyte przez użytkownika ani w mieście
  allBadges.forEach((badge) => {
    if (!badgeMap.has(badge.id)) {
      badgeMap.set(badge.id, {
        id: badge.id,
        name: badge.name || "Brak nazwy",
        description: badge.description || "Brak opisu",
        isAchieved: false,
      });
    }
  });

  // Konwersja mapy na tablicę i sortowanie (zdobyte na początku)
  const displayedBadges = Array.from(badgeMap.values()).sort((a, b) => b.isAchieved - a.isAchieved);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-2xl font-bold text-green-700 mb-6">Moje Odznaki</h2>

      {/* Siatka odznak */}
      <div className="grid grid-cols-5 gap-10">
        {displayedBadges.map((badge) => (
          <BadgeItem key={badge.id} badge={badge} onClick={() => handleBadgeClick(badge.id)} />
        ))}
      </div>

      {/* Modal ze szczegółami odznaki */}
      {selectedBadge && (
        <BadgeDetailsModal
          badge={badgeDetails?.allActivitiesBadges?.nodes?.[0] || null}
          isLoading={detailsLoading}
          onClose={() => setSelectedBadge(null)}
        />
      )}
    </div>
  );
};

// Komponent pojedynczej odznaki
const BadgeItem = ({ badge, onClick }) => {
  const badgeImage = badge.isAchieved ? badgeGoldIcon : badgeIcon;

  return (
    <div className="flex flex-col items-center p-4 rounded-xl shadow-custom border border-gray-200 cursor-pointer transition" onClick={onClick}>
      <img src={badgeImage} alt={badge.name} className="w-30 h-30 rounded-full" />
      <p className="text-xl font-semibold mt-2 text-center text-secondary">{badge.name}</p>
      <p className="text-base text-secondary text-center p-0 m-0">
        {badge.description.length > 35 ? `${badge.description.substring(0, 35)}...` : badge.description}
      </p>
    </div>
  );
};

// Komponent szczegółów odznaki (modal)
const BadgeDetailsModal = ({ badge, isLoading, onClose }) => {
  if (isLoading) return <p className="text-center text-gray-500">Ładowanie szczegółów...</p>;
  if (!badge) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold text-green-700">{badge.name || "Brak nazwy"}</h2>
        <p className="text-gray-600 mt-2">{badge.description || "Brak opisu"}</p>
        <img src={badge.image || badgeGoldIcon} alt={badge.name} className="w-32 h-32 mx-auto my-4" />

        <button onClick={onClose} className="w-full bg-red-500 text-white py-2 rounded-lg mt-4">
          Zamknij
        </button>
      </div>
    </div>
  );
};

export default Badges;
