import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import Logo from '../assets/icons/aktywne_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Navbar() {

    const { t } = useTranslation();

    const [dropdownOpenHelp, setDropdownOpenHelp] = useState(false);
    const [dropdownOpenPartners, setDropdownOpenPartners] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isNavClosing, setIsNavClosing] = useState(false);

    const toggleDropdownHelp = () => {
        setDropdownOpenHelp(!dropdownOpenHelp);
    };

    const toggleDropdownPartners = () => {
        setDropdownOpenPartners(!dropdownOpenPartners);
    };

    const toggleNav = () => {
        if (windowWidth <= 1200) {
            if (isNavOpen) {
                setIsNavClosing(true);
                setTimeout(() => {
                    setIsNavOpen(false);
                    setIsNavClosing(false);
                }, 300);
            } else {
                setIsNavOpen(true);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 1200) {
                setIsNavOpen(true);
            } else {
                setIsNavOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLoginRedirect = () => {
        const appDeepLink = "aktywnemiasta://login"; // Link do aplikacji mobilnej
        const androidStoreLink = "https://play.google.com/store/apps/details?id=pl.bydgoszcz.aktywna&amp;pcampaignid=web_share";
        const iosStoreLink = "https://apps.apple.com/us/app/aktywne-miasta/id1317715080"; // Zamień na prawdziwy App Store ID
    
        // Sprawdzenie systemu operacyjnego użytkownika
        const isAndroid = /Android/i.test(navigator.userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    
        // Otwórz aplikację mobilną
        window.location.href = appDeepLink;
    
        // Jeśli aplikacja nie jest zainstalowana, przekieruj do sklepu po 1 sekundzie
        setTimeout(() => {
            if (isAndroid) {
                window.location.href = androidStoreLink;
            } else if (isIOS) {
                window.location.href = iosStoreLink;
            }
        }, 1000);
    };
    

    return (
        <nav className="navbar">
            <Link to="/" className="navbar-logo">
                <img src={Logo} alt="Aktywne Miasta logo" />
            </Link>
            <button onClick={toggleNav} className="hamburger-btn">
                {isNavOpen ? '✕' : '☰'}
            </button>
            {isNavOpen && <div className="nav-mobile-overlay" onClick={toggleNav}></div>}
            {(windowWidth <= 1200 && (isNavOpen || isNavClosing)) && (
                <ul className={`nav-items-mobile ${isNavOpen ? 'open' : ''} ${isNavClosing ? 'closing' : ''}`}>
                    <Link to="/" className="navbar-mobile-logo">
                        <img src={Logo} alt="Aktywne Miasta logo" />
                    </Link>
                    <li className="nav-item">
                        <Link to="/news" onClick={toggleNav}>{t('menu.aktualności')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/function" onClick={toggleNav}>{t('menu.funkcjonalność')}</Link>
                    </li>
                    <li className="nav-item" onClick={toggleDropdownHelp} style={{ paddingBottom: dropdownOpenHelp ? '0' : '20px' }}>
                    <span style={{ display: 'block', paddingBottom: dropdownOpenHelp ? '20px' : '0', borderBottom: dropdownOpenHelp ? "1px solid rgba(0, 69, 25, 0.5)" : 'none'}}>
                        {t('menu.pomoc_techniczna')} {dropdownOpenHelp ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                    </span>
                        {dropdownOpenHelp && (
                            <>
                                <li><Link to="/help" style={{ textTransform: 'none' }} onClick={toggleNav}>{t('menu.najczęstsze_problemy')}</Link></li>
                                <li><Link to="/tutorial" style={{ textTransform: 'none' }} onClick={toggleNav}>{t('menu.filmy_instruktażowe')}</Link></li>
                            </>
                        )}
                    </li>
                    <li className="nav-item" onClick={toggleDropdownPartners} style={{ paddingBottom: dropdownOpenPartners ? '0' : '20px' }}>
                    <span style={{ display: 'block', paddingBottom: dropdownOpenPartners ? '20px' : '0', borderBottom: dropdownOpenPartners ? "1px solid rgba(0, 69, 25, 0.5)" : 'none' }}>
                        {t('menu.dla_partnerów')} {dropdownOpenPartners ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                    </span>
                        {dropdownOpenPartners && (
                            <>
                                <li><Link to="/samorzad" style={{ textTransform: 'none' }} onClick={toggleNav}>{t('menu.samorządowych')}</Link></li>
                                <li><Link to="/biznes" style={{ textTransform: 'none' }} onClick={toggleNav}>{t('menu.biznesowych')}</Link></li>
                            </>
                        )}
                    </li>
                    <li className="nav-item">
                        <a href="https://sklep.visitbydgoszcz.pl/pl/c/Aktywne-Miasta/39" target="_blank" rel="noopener noreferrer">{t('menu.sklep')}</a>
                    </li>
                    {/* <li className='nav-item'>
                        <button onClick={handleLoginRedirect} className="login-button text-white uppercase font-semibold p-0 text-base">Logowanie</button>
                    </li> */}
                    <li className="nav-item">
                        <div className="contact-column" style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center"}}>
                                <a href="https://www.facebook.com/aktywnemiastapl/" target="_blank" rel="noopener noreferrer" style={{marginRight: "15px"}}>
                                    <FontAwesomeIcon icon={faFacebookF} size="2x" style={{ color: '#FFF' }}/>
                                </a>
                                <a href="https://www.youtube.com/channel/UCg5q4GLYXbtl6Y37v7MYHGQ" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} size="2x" style={{ color: '#FFF' }}/>
                                </a>
                                <a href="https://www.instagram.com/aktywnemiasta.pl/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px"}}>
                                    <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#FFF' }}/>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            )}
            {(windowWidth > 1200) && (
                <ul className="nav-items">
                <li className="nav-item">
                    <Link to="/news">{t('menu.aktualności')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/function">{t('menu.funkcjonalność')}</Link>
                </li>
                <li
                    className="nav-item dropdown"
                    onClick={toggleDropdownHelp}
                >
                    <button className="dropdown-toggle">{t('menu.pomoc_techniczna')}<FontAwesomeIcon icon={faAngleDown}/></button>
                    {dropdownOpenHelp && (
                        <ul className="dropdown-menu">
                            <li><Link to="/help">{t('menu.najczęstsze_problemy')}</Link></li>
                            <li><Link to="/tutorial">{t('menu.filmy_instruktażowe')}</Link></li>
                        </ul>
                    )}
                </li>
                <li
                    className="nav-item dropdown"
                    onClick={toggleDropdownPartners}
                >
                    <button className="dropdown-toggle">{t('menu.dla_partnerów')}<FontAwesomeIcon icon={faAngleDown}/></button>
                    {dropdownOpenPartners && (
                        <ul className="dropdown-menu">
                            <li><Link to="/samorzad">{t('menu.samorządowych')}</Link></li>
                            <li><Link to="/biznes">{t('menu.biznesowych')}</Link></li>
                        </ul>
                    )}
                </li>
                <li className="nav-item">
                    <a href="https://sklep.visitbydgoszcz.pl/pl/c/Aktywne-Miasta/39" target="_blank" rel="noopener noreferrer">{t('menu.sklep')}</a>
                </li>
                {/* <li className='nav-item'>
                    <Link to="/account/login" onClick={toggleNav}>Logowanie</Link>
                </li> */}
            </ul>
            )}
        </nav>
    );
}

export default Navbar;
