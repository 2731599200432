import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Link, useOutletContext } from 'react-router-dom';
import apolloClient from '../../../queries/ApolloUser';
import { GET_USER_ACTIVITIES, GET_USER_GPS } from '../../../queries/GraphQL_Query';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import { FaCheck } from 'react-icons/fa';
import L from 'leaflet';

const Activities = () => {
  const [isComparing, setIsComparing] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState(new Set());
  const [gpsData, setGpsData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const { selectedSportId } = useOutletContext(); // ⬅ Pobieramy wartość z UserAccountPage
  const [loadingScreen, setLoadingScreen] = useState(true);

  const mapRef = useRef();
  const userId = localStorage.getItem('userId');
  const colors = ['blue', 'orange', 'green', 'red', 'yellow'];



const toggleActivitySelection = (index) => {
  setSelectedActivities((prevSelected) => {
    const newSelected = new Set(prevSelected);
    if (newSelected.has(index)) {
      newSelected.delete(index);
    } else if (newSelected.size < 4) {
      newSelected.add(index);
    }
    return newSelected;
  });
};


  const { loading, error, data } = useQuery(GET_USER_ACTIVITIES, {
    client: apolloClient,
    variables: { userId, offset: 0 },
    onCompleted: () => {
      setTimeout(() => setLoadingScreen(false), 2000);
    },
  });

  const [fetchGpsData] = useLazyQuery(GET_USER_GPS, {
    client: apolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response && response.allActivitiesActivitygps) {
        setGpsData(response.allActivitiesActivitygps.nodes);
      }
    },
  });

  useEffect(() => {
    if (showMap && selectedActivities.size > 0) {
      const selectedIds = Array.from(selectedActivities).map((index) => activitiesData[index].activityId);
      fetchGpsData({ variables: { in: selectedIds } });
    }    
  }, [selectedActivities, showMap]);

  useEffect(() => {
    if (showMap && gpsData.length > 0 && mapRef.current) {
      const bounds = calculateBounds();
      if (bounds) mapRef.current.fitBounds(bounds);
    }
  }, [gpsData, showMap, selectedActivities]);  

  if (!userId) return <p>Proszę się zalogować, aby zobaczyć aktywności.</p>;
  if (loading) return <p>Ładowanie danych...</p>;
  if (error) return <p>Błąd: {error.message}</p>;

  if (!data?.allActivitiesActivityindividuals) {
    return <p>Brak danych aktywności.</p>;
  }

  const activitiesData = data.allActivitiesActivityindividuals.nodes.map((activity) => ({
    activityId: activity.activityId,
    date: new Date(activity.beginDate).toLocaleString(),
    duration: activity.endTime != null ? 
      `${Math.floor(activity.endTime / 60)}:${String(activity.endTime % 60).padStart(2, '0')}` : 
      '00:00',
    calories: activity.endCalories != null ? activity.endCalories : 0,
    distance: activity.endDistance != null ? activity.endDistance : 0,
    speed: activity.endSpeed != null ? activity.endSpeed : 0,
    sportId: activity.sportId,
}));

console.log("activitiesData:", activitiesData);


  // Filter activities based on selected sport ID
  const filteredActivities = selectedSportId
  ? activitiesData.filter((activity) => Number(activity.sportId) === Number(selectedSportId))
  : activitiesData;

// Use filteredActivities for calculating total activities, duration, calories, and distance
const totalActivities = filteredActivities.length;
const totalDuration = filteredActivities.reduce((total, activity) => {
  const [hours, minutes] = activity.duration.split(':').map(Number);
  return total + hours * 60 + minutes;
}, 0);
const totalCalories = filteredActivities.reduce((total, activity) => total + (activity.calories || 0), 0);
const totalDistance = filteredActivities.reduce((total, activity) => total + (activity.distance || 0), 0);

const formattedTotalDistance = totalDistance.toFixed(2).replace('.', ',');
const hours = Math.floor(totalDuration / 60);
const minutes = totalDuration % 60;
const formattedTotalDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

const topSpeed = filteredActivities.length > 0
  ? Math.max(...filteredActivities.map((activity) => activity.speed || 0)).toFixed(2).replace('.', ',')
  : '0,00';

const maxCalories = filteredActivities.length > 0 
  ? Math.max(...filteredActivities.map((activity) => activity.calories || 0)) 
  : 0;

const maxDistance = filteredActivities.length > 0 
  ? Math.max(...filteredActivities.map((activity) => activity.distance || 0)).toFixed(2).replace('.', ',') 
  : '0,00';

const longestDuration = filteredActivities.length > 0 
  ? `${Math.floor(Math.max(...filteredActivities.map((activity) => {
      const [hours, minutes] = (activity.duration || '00:00').split(':').map(Number);
      return (hours || 0) * 60 + (minutes || 0);
    })) / 60)}:${String(Math.max(...filteredActivities.map((activity) => {
      const [hours, minutes] = (activity.duration || '00:00').split(':').map(Number);
      return (hours || 0) * 60 + (minutes || 0);
    })) % 60).padStart(2, '0')}`
  : '00:00';

  const handleCompareClick = () => {
    if (selectedActivities.length < 2) {
      alert('Wybierz przynajmniej dwie aktywności do porównania.');
      return;
    }
    setIsComparing(true);
    setShowMap(true);
  };

  const handleCancelComparison = () => {
    setIsComparing(false);
    setSelectedActivities([]);
    setGpsData([]);
    setShowMap(false);
  };

  const calculateBounds = () => {
    const latLngs = gpsData.flatMap((gps) =>
      gps.latitude && gps.longitude ? [[gps.latitude, gps.longitude]] : []
    );
    return latLngs.length ? L.latLngBounds(latLngs) : null;
  };


  filteredActivities.forEach(activity => {
    console.log("Aktywność:", activity);
    console.log("calories:", activity.calories);
    console.log("distance:", activity.distance);
    console.log("speed:", activity.speed);
  });
  
  return (
    <div className="p-4">
      {/* FilterBar for selecting sport */}
      {/* <FilterBar showFilterBar={true} onActivitySelect={setSelectedSportId} /> */}

      {/* Statistics */}
      <h2 className="text-2xl text-primary-dark font-bold !mb-4 uppercase">Statystyki</h2>
      {!isComparing && (
        <>
          <div className="grid grid-cols-4 gap-12 mb-20">
            <div className="p-3 bg-white rounded-3xl shadow-custom text-center flex flex-col items-center pb-6">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_run.svg`} alt="Liczba aktywności" className="w-12 h-12" />
              <h3 className="text-lg font-bold">Liczba aktywności</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{totalActivities}</p>
            </div>
            <div className="p-3 bg-white rounded-3xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_timer-outline.svg`} alt="Łączny czas" className="w-12 h-12" />
              <h3 className="text-lg font-bold">Łączny czas</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{formattedTotalDuration}</p>
            </div>
            <div className="p-3 bg-white rounded-3xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_cupcake.svg`} alt="Spalone kalorie" className="w-12 h-12" />
              <h3 className="text-lg font-bold">Spalone kalorie</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{totalCalories} kcal</p>
            </div>
            <div className="p-3 bg-white rounded-3xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_map-marker-distance.svg`} alt="Przebyty dystans" className="w-12 h-12" />
              <h3 className="text-lg font-bold">Przebyty dystans</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{formattedTotalDistance} km</p>
            </div>
          </div>

          {/* Record Section */}
          <h2 className="text-2xl text-primary-dark font-bold mb-6 uppercase">Rekordy</h2>
          <div className="grid grid-cols-4 gap-12 mb-6">
          <div className="p-2 bg-white rounded-3xl shadow-custom text-center pb-6">
              <div className="relative w-16 h-16 mx-auto">
                <img src={`${process.env.PUBLIC_URL}/images/user_account/top_speed.svg`} alt="Medal Icon" className="w-full h-full" />
              </div>
              <h3 className="text-lg font-bold">Największa prędkość</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{topSpeed} km/h</p>
            </div>
            <div className="p-2 bg-white rounded-3xl shadow-custom text-center">
              <div className="relative w-16 h-16 mx-auto">
                <img src={`${process.env.PUBLIC_URL}/images/user_account/most_calories.svg`} alt="Medal Icon" className="w-full h-full" />
              </div>
              <h3 className="text-lg font-bold">Najwięcej spalonych kalorii</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{maxCalories} kcal</p>
            </div>
            <div className="p-2 bg-white rounded-3xl shadow-custom text-center">
              <div className="relative w-16 h-16 mx-auto">
                <img src={`${process.env.PUBLIC_URL}/images/user_account/longest_distance.svg`} alt="Medal Icon" className="w-full h-full" />
              </div>
              <h3 className="text-lg font-bold">Najdłuższy dystans</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{maxDistance} km</p>
            </div>
            <div className="p-2 bg-white rounded-3xl shadow-custom text-center">
              <div className="relative w-16 h-16 mx-auto">
                <img src={`${process.env.PUBLIC_URL}/images/user_account/longest_duration.svg`} alt="Medal Icon" className="w-full h-full" />
              </div>
              <h3 className="text-lg font-bold">Najdłuższy czas</h3>
              <p className="text-xl lg:text-4xl text-secondary font-bold m-0 p-0" style={{lineHeight: "30px"}}>{longestDuration}</p>
            </div>
          </div>
        </>
      )}

      {/* Comparison and Map Logic */}
      {isComparing ? (
        <div className="flex justify-start items-center space-x-4 mb-4">
          <span>Zaznacz treningi, aby je porównać</span>
          <button
            onClick={handleCompareClick}
            className="rounded-full py-5 px-12 bg-[#71B744] text-base font-semibold text-white uppercase border-none"
          >
            Porównaj
          </button>
          <button
            onClick={handleCancelComparison}
            className="rounded-full py-5 px-6 bg-[#F25B2E] text-base font-semibold text-white uppercase border-none"
          >
            Anuluj porównanie
          </button>

        </div>
      ) : (
        <div className="flex justify-end my-16">
          <button
            onClick={() => setIsComparing(true)}
            className="rounded-3xl py-3 px-8 bg-primary-light text-secondary text-base font-bold text-right border-none hover:bg-primary-dark hover:text-white"
          >
            Porównaj treningi
          </button>
        </div>
      )}

      {showMap && (
        <MapContainer ref={mapRef} zoom={13} style={{ height: '400px', width: '100%' }} className="my-6 rounded-xl shadow-custom">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {gpsData.length > 0 &&
            Array.from(selectedActivities).map((index) => {
              if (!filteredActivities[index]) return null;
              
              const activityId = filteredActivities[index].activityId;
              const coordinates = gpsData
                .filter((gps) => gps.activityId === activityId)
                .map((gps) => [gps.latitude, gps.longitude]);

              if (coordinates.length === 0) return null; // Jeśli brak danych GPS, nie renderuj Polyline

              return <Polyline key={activityId} positions={coordinates} color={colors[index % colors.length]} />;
            })}

        </MapContainer>
      )}

      {/* Activities List */}
      <h2 className="text-2xl text-primary-dark font-bold mb-4 uppercase">Aktywności</h2>

      
      <table className="min-w-full bg-white">
      <tbody>
        {filteredActivities.map((activity, index) => (
          <tr key={index} className={`flex relative ${index % 2 === 0 ? 'bg-primary-light' : ''} hover:bg-gray-100`}>
            {/* Checkbox for Selecting Activities */}
              {isComparing && (
                <td className='absolute -left-8 top-5bg-white mt-6 mb-6'>
                  <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedActivities.has(index)}
                    onChange={(e) => {
                      e.stopPropagation(); // Stop event from reaching parent
                      toggleActivitySelection(index);
                    }}
                    className="appearance-none absolute h-5 w-5 border-2 border-green-600 border-solid rounded-full cursor-pointer bg-white checked:bg-[#F25B2E] checked:border-transparent"
                  />
                  {selectedActivities.has(index) && (
                    <span className="absolute inset-0 flex items-center justify-center text-white">
                      <FaCheck className="text-xs" />
                    </span>
                  )}
                  </label>
                </td>
              )}

            {/* Activity Data - Wrapped in Link */}
            <td className="border-t py-3 px-4 w-52">
                {isComparing ? (
                  <span>{activity.date}</span>
                ) : (
                  <Link to={`/account/activities/${activity.activityId}`} className="no-underline text-black">
                    {activity.date}
                  </Link>
                )}
              </td>
            <td className="flex-1 border-t py-2 px-4 flex items-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/user_account/icon_timer-outline_yellow.svg`}
                alt="Duration Icon"
                className="w-4 h-4 mr-2"
              />
              <span>{activity.duration}</span>
            </td>
            <td className="flex-1 border-t py-2 px-4 flex items-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/user_account/icon_cupcake_yellow.svg`}
                alt="Calories Icon"
                className="w-4 h-4 mr-2"
              />
              <span>{activity.calories} kcal</span>
            </td>
            <td className="flex-1 border-t py-2 px-4 flex items-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/user_account/icon_map-marker-distance_yellow.svg`}
                alt="Distance Icon"
                className="w-4 h-4 mr-2"
              />
              <span>{activity.distance.toFixed(2).replace('.', ',')} km</span>
            </td>
            <td className="flex-1 border-t py-2 px-4 flex items-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/user_account/icon_speedometer_yellow.svg`}
                alt="Speed Icon"
                className="w-4 h-4 mr-2"
              />
              <span>{activity.speed.toFixed(2).replace('.', ',')} km/h</span>
            </td>
          </tr>
        ))}
      </tbody>

      </table>
    </div>
  );
};

export default Activities;
