import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Dodano Link
import { useUserContext } from '../components/UserContext';
import { GoogleOAuthProvider} from '@react-oauth/google';
import GoogleLoginButton from '../components/GoogleLogin'; // Upewnij się, że ścieżka pasuje do Twojej struktury
// import FacebookLogin from 'react-facebook-login-lite';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { FaFacebookF } from "react-icons/fa"; // Import niestandardowej ikony
import AuthLayout from '../AuthLayout';

function LoginPage() {
  const [login, setLogin] = useState('');
  const [pass, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { saveUserData } = useUserContext();

  const logo = "/images/user_account/logo.svg";

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: '2080373255312950',
          cookie: true,
          xfbml: true,
          version: 'v18.0',
        });
  
        window.FB.getLoginStatus((response) => {
          console.log('Facebook Login Status:', response);
          if (response.status === 'connected') {
            handleFacebookResponse(response);
          } else {
            window.FB.login(handleFacebookResponse, { scope: 'public_profile,email' });
          }
        });
      }
    };
  
    // Load the Facebook SDK script dynamically
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        loadFacebookSDK();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.onload = loadFacebookSDK; // Ensure SDK is loaded before initialization
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      handleFacebookResponse(response);
    }
  };

  const handleLoginChange = (e) => setLogin(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('login', login);
      formData.append('pass', pass);
      formData.append('registration_id', 'xxx');

      const response = await fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/user_login', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.mess?.[0] || 'Błędne dane logowania');
      }

      const data = await response.json();
      if (data.result === 'success' && data.data && data.data.id) {
        saveUserData(data.data);
        setTimeout(() => navigate('/account/activities'), 2000);
      } else {
        throw new Error('Nie udało się pobrać ID użytkownika.');
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-white">
        <img src={logo} alt="Logo" className="w-60 h-60 animate-pulse" />
      </div>
    );
  }

  const handleFacebookResponse = (response) => {
    console.log('Facebook response:', response);
  
    if (response.accessToken) {
      fetch(`https://aplikacja.aktywna.bydgoszcz.pl/api/facebook_login?access_token=${response.accessToken}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('API Response:', data);
  
          if (data.result === 'success' && data.data && data.data.id) {
            saveUserData(data.data);
            navigate('/account/activities');
          } else {
            setError('Błąd logowania przez Facebook');
          }
        })
        .catch((error) => {
          console.error('Login error:', error);
          setError(error.message);
        });
    }
  };
  
  


  const handleGoogleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/google_login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 'success') {
          saveUserData(data.data);
          navigate('/account/activities');
        } else {
          setError('Błąd logowania przez Google');
        }
      })
      .catch((error) => setError(error.message));
  };

  const CustomComponent = ({ onClick }) => (
    <button
      onClick={onClick}
      className="w-full bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-800 transition duration-200 flex justify-center items-center shadow-md py-2"
    >
      <FaFacebookF className="text-white text-xl mr-2" />
      <span className="text-base">Zaloguj przez Facebook</span>
    </button>
  );
  return (
    <GoogleOAuthProvider clientId="396321888808-dupm9jocnolthfm4964l2dl89qkk3seg.apps.googleusercontent.com">
      <div className="flex justify-center mx-auto items-center">
        <div className="w-full max-w-md p-8">
          <h2 className="text-3xl font-bold text-[#016623] py-5 text-center">Logowanie</h2>
          {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Login</label>
              <input
                type="text"
                id="username"
                value={login}
                onChange={handleLoginChange}
                className="mt-1 px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#016623] focus:border-[#016623]"
                placeholder="Wprowadź login"
                required
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Hasło</label>
              <input
                type="password"
                id="password"
                value={pass}
                onChange={handlePasswordChange}
                className="mt-1 px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#016623] focus:border-[#016623]"
                placeholder="Wprowadź hasło"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 text-base bg-gradient-to-r from-[#72BF44] to-[#003C10] text-white font-semibold rounded-full hover:bg-[#004d2b] transition duration-200" style={{margin: '20px 0'}}
            >
              Zaloguj
            </button>
          </form>

          {/* Facebook Login */}
          <div className="flex flex-col space-y-4">
            <FacebookLogin
              // appId="2080373255312950"
              onSuccess={(response) => {
                console.log('Login Success!', response);
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
              render={({ onClick, logout }) => (
                <CustomComponent onClick={onClick} onLogoutClick={logout} />
              )}
              // render={(renderProps) => (
              //   <button
              //     onClick={renderProps.onClick}
              //     className="w-full bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-800 transition duration-200 flex justify-center items-center shadow-md"       style={{ padding: '15px', fontSize: '18px', borderRadius: '12px' }} // Dodatkowe style inline

              //   >
              //     {/* Zmieniona ikona */}
              //     <FaFacebookF className="text-white text-xl mr-2" />
              //     <span className="text-lg">Zaloguj przez Facebook</span>
              //   </button>
              // )}'
              children='Zaloguj przez Facebook'
              fields='picture, name, email'
            />
             {/* <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={() => setError('Błąd logowania przez Google')}
              render={(renderProps) => (
                <button onClick={renderProps.onClick} className="w-full py-4 bg-red-500 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200 flex justify-center items-center">
                  <span className="material-icons mr-2">account_circle</span>
                  Zaloguj przez Google
                </button>
              )}
            /> */}
            <GoogleLoginButton onSuccess={handleGoogleLoginSuccess} onError={() => setError('Błąd logowania przez Google')} id="customBtn" class="customGPlusSignIn" style={{height: '200px'}}/>

          </div>

          <p className="text-center mt-6 text-sm text-gray-600">
            Nie masz jeszcze konta?{' '}
            <Link to="/account/register" className="text-[#016623] font-semibold hover:underline">
              Zarejestruj się
            </Link>
          </p>

        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default LoginPage;
