import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import apolloClient from "../../../queries/ApolloUser";
import { useUserContext } from '../components/UserContext';
import { GET_GOALS, GET_GOAL_FRIENDS } from "../../../queries/GraphQL_Query";
import { Link } from "react-router-dom";;

const checkIcon = "/images/user_account/check.svg";

const Goals = () => {
  const [view, setView] = useState("moje");
  const [filter, setFilter] = useState("Zdobyte");
  const [friendGoals, setFriendGoals] = useState([]);
  const { userId } = useUserContext();

  const { loading, error, data } = useQuery(GET_GOALS, {
    client: apolloClient,
    variables: { userIds: [userId], filter: {} },  // Ensure filter is passed
    fetchPolicy: "cache-first",
  });

  // Pobieranie znajomych użytkownika
  const { data: friendsData } = useQuery(GET_GOAL_FRIENDS, {
    client: apolloClient,
    variables: { userId },
    skip: !userId,
    fetchPolicy: "cache-first",
  });

  // Pobieranie celów znajomych (tylko raz)
  const [fetchFriendGoals] = useLazyQuery(GET_GOALS, {
    client: apolloClient,
    fetchPolicy: "cache-first",
    onCompleted: (response) => {
      setFriendGoals(response?.allActivitiesGoals?.nodes || []);
    },
  });

  const handleViewChange = async (selectedView) => {
    setView(selectedView);
  
    if (selectedView === "znajomi") {
      if (!friendsData) return;
  
      // Extract unique friend IDs
      const friendIds = [...new Set(friendsData.allAccountsFriendinvitations.nodes
        .map(friend => friend.friendId !== userId ? friend.friendId : friend.userId)
        .filter(id => id !== userId) // Exclude logged-in user
      )];
  
      if (friendIds.length > 0) {
        try {
          const { data: friendGoalsData } = await fetchFriendGoals({
            variables: { userIds: friendIds }, // Fetch ALL friend goals in one request
          });
  
          setFriendGoals(friendGoalsData?.allActivitiesGoals?.nodes || []);
        } catch (error) {
          console.error("Błąd podczas pobierania celów znajomych:", error);
        }
      }
    } else {
      setFriendGoals([]); // Clear friend goals when switching back to "Moje Cele"
    }
  };
  
  const allFriends = friendsData?.allAccountsFriendinvitations?.nodes.reduce((acc, friend) => {
    const friendId = friend.friendId !== userId ? friend.friendId : friend.userId;
  
    if (!acc[friendId]) {
      acc[friendId] = {
        id: friendId,
        name: "Nieznajomy",
        goals: []
      };
    }
  
    // Find the first goal to extract user details
    const userData = friendGoals.find(goal => goal.userId === friendId)?.accountsUserByUserId;
    if (userData) {
      acc[friendId].name = `${userData.firstName} ${userData.lastName}`;
    }
  
    // Assign goals
    acc[friendId].goals = friendGoals.filter(goal => goal.userId === friendId);
  
    return acc;
  }, {});

  if (loading) return <p className="text-center text-gray-500">Ładowanie...</p>;
  if (error) return <p className="text-center text-red-500">Błąd: {error.message}</p>;

  const fetchedGoals = view === "moje" ? data?.allActivitiesGoals?.nodes || [] : friendGoals;
  const filteredGoals = fetchedGoals.filter(goal => filter === "Zdobyte" ? goal.achieved : !goal.achieved);

  return (
    <div className="max-w-6xl mx-auto p-6">
  {/* Przełączanie między Moimi Celami a Celami Znajomych */}
  <div className="flex justify-center gap-4 mb-6">
    <button
      className={`py-4 w-64 rounded-full shadow-custom text-base font-semibold ${view === "moje" ? "bg-primary-green text-white" : "bg-white"}`}
      onClick={() => handleViewChange("moje")}
    >
      Moje Cele
    </button>
    <button
      className={`py-4 w-64 rounded-full shadow-custom text-base font-semibold ${view === "znajomi" ? "bg-primary-green text-white" : "bg-white"}`}
      onClick={() => handleViewChange("znajomi")}
    >
      Cele Znajomych
    </button>
  </div>

  {/* Filtrowanie Zdobytych i Niezdobytych Celów */}
  <div className="flex justify-center gap-4 mb-4">
    <button
      className={`py-2 w-28 rounded-full shadow-custom font-semibold ${filter === "Zdobyte" ? "bg-primary-green text-white" : "bg-white"}`}
      onClick={() => setFilter("Zdobyte")}
    >
      Zdobyte
    </button>
    <button
      className={`py-2 w-28 rounded-full shadow-custom font-semibold ${filter === "Niezdobyte" ? "bg-primary-green text-white" : "bg-white"}`}
      onClick={() => setFilter("Niezdobyte")}
    >
      Niezdobyte
    </button>
  </div>

  {view === "moje" ? (
  filteredGoals.length > 0 ? (
    filteredGoals.map(goal => <GoalItem key={goal.id} goal={goal} />)
  ) : (
    <p className="text-center text-gray-500">Brak celów do wyświetlenia.</p>
  )
) : (
  Object.entries(allFriends).length > 0 ? (
    Object.entries(allFriends).map(([friendId, friend]) => (
      <div key={friendId} className="mb-6">
        <h2 className="text-lg font-bold text-green-700">{friend.name}</h2>
        {friend.goals.length > 0 ? (
          friend.goals
            .filter(goal => filter === "Zdobyte" ? goal.achieved : !goal.achieved)
            .map(goal => <GoalItem key={goal.id} goal={goal} />)
        ) : (
          <p className="text-sm text-gray-500">Brak celów.</p>
        )}
      </div>
    ))
  ) : (
    <p className="text-center text-gray-500">Brak znajomych do wyświetlenia.</p>
  )
)}

  {/* Przycisk Dodaj Cel */}
  <div className="flex justify-center mt-6">
    <Link to={`/account/goals/create`} className="px-6 py-3 bg-primary-green text-white font-semibold rounded-full shadow-custom hover:bg-green-700 transition">
      Dodaj cel
    </Link>
  </div>
</div>
)};

// / Komponent dla pojedynczego celu
const GoalItem = ({ goal }) => {
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "Brak daty"; // Obsługa pustej wartości
  
    const date = new Date(isoDate);
    if (isNaN(date)) return "Nieprawidłowa data"; // Obsługa niepoprawnych dat
  
    return new Intl.DateTimeFormat("pl-PL", { 
      day: "numeric", 
      month: "long", 
      year: "numeric" 
    }).format(date);
  };

  const formatGoalProgress = (goal) => {
    if (goal.type === "1") {
      return `${goal.progress} km / ${goal.value} km`; // Dystans
    } else if (goal.type === "2") {
      return `${goal.progress} kcal / ${goal.value} kcal`; // Kalorie
    } else {
      return `${formatTime(goal.progress)} / ${formatTime(goal.value)}`; // Czas
    }
  };

  const nameOfGoals = goal.type === '1' ? `Przebyć dystans ${goal.value} km` : goal.type === '2' ? `Spalić ${goal.value} kcal` : `Trenować przez ${formatTime(goal.value)}`;

  const inOneOrMoreActivities = goal.inOneActivity === true ? "Podczas jednej aktywności" : "Podczas wielu aktywności";

  const progresPercentage = goal.value > 0 ? (goal.progress / goal.value) * 100 : 0;
  return (
    <div className="flex items-center pb-4 px-4 my-6 bg-white rounded-xl shadow-custom border border-gray-200 mb-2">
      {/* Ikona i tekst */}
      <div className="flex items-center gap-4 w-full">
        <span className="text-3xl">{goal.icon || "🎯"}</span>
        <div className="flex flex-col w-full">
         <div className="flex justify-between">
            <div>
              <p className="font-semibold text-lg text-green-700">{nameOfGoals}</p>
              <p className="text-gray-500 text-sm pb-4">{inOneOrMoreActivities}</p>
            </div>
            <div>        
              <p>{formatDate(goal.achievedDate)}</p>
              <p>{formatGoalProgress(goal)}</p>
            </div>
         </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div className="h-2 bg-gradient-to-r from-[#72BF44] to-[#003C10] rounded-full w-full" style={{ width: `${progresPercentage}%`}}></div>
          </div>
        </div>
      </div>

      {/* Ikona potwierdzenia */}
      {goal.achieved && <span className="text-primary-green text-3xl ml-4"> <img
        src={checkIcon}
        alt="Check"
        // className="absolute bottom-0 right-0 w-30"zs
      /></span>}
    </div>
  );
};

export default Goals;
