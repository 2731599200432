import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Sidebar.css";
import { useUserContext } from './UserContext';
import { BASE_IMAGE_URL, CITIES_URL } from '../../../queries/api';

// Komponent do renderowania ikony
const Icon = ({ src, alt }) => (
  <img
    src={`${process.env.PUBLIC_URL}/images/user_account/${src}`}
    className="w-5 h-5 pr-5"
    alt={alt}
  />
);

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const { firstName, lastName, profileImage, cityId } = useUserContext();
  const [cityName, setCityName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!cityId) return;
  
    const fetchCityName = async () => {
      try {
        const res = await fetch(`${CITIES_URL}${cityId}/`);
  
        const contentType = res.headers.get('content-type');
        if (!res.ok || !contentType || !contentType.includes('application/json')) {
          const text = await res.text(); // logujemy co przyszło
          throw new Error(`Niepoprawna odpowiedź: ${res.status}, Body: ${text.slice(0, 100)}...`);
        }
  
        const data = await res.json();
        setCityName(data.name || 'Unknown City');
      } catch (error) {
        console.error("Error fetching city name:", error.message);
        setCityName('Unknown City');
      }
    };
  
    fetchCityName();
  }, [cityId]);
  
  

  // Generowanie URL zdjęcia profilowego
  const profileImageUrl = profileImage
    ? `${BASE_IMAGE_URL}${profileImage}`
    : 'https://via.placeholder.com/100';

  // Ikony do menu
  const iconMapping = {
    statistics: { src: 'menu_statistics.svg', alt: 'Statystyki' },
    competitions: { src: 'menu_podium.svg', alt: 'Rywalizacje' },
    goals: { src: 'menu_goals.svg', alt: 'Cele' },
    badges: { src: "menu_badge.svg", alt: "Odznaki" },
    logout: { src: 'menu_logout.svg', alt: 'Wyloguj' }
  };

  const handleLogout = () => {
    localStorage.removeItem('userId'); // Usuń ID użytkownika
    localStorage.removeItem('authToken'); // Jeśli masz token, też go usuń
    sessionStorage.clear(); // Opcjonalnie usuń dane sesji
    navigate('/account/login', { replace: true }); // Zastąp stronę w historii
    window.location.reload(); // Odśwież stronę, by usunąć pamięć podręczną
  };
  

  // Elementy menu
  const navItems = [
    {
      to: '/account/activities',
      label: 'Statystyki i historia',
      iconKey: 'statistics',
      onClick: toggleSidebar,
    },
    {
      to: '/account/competitions',
      label: 'Rywalizacje',
      iconKey: 'competitions',
      onClick: toggleSidebar,
    },
    {
      to: '/account/goals',
      label: 'Cele',
      iconKey: 'goals',
      onClick: toggleSidebar,
    },
    {
      to: '/account/badges',
      label: 'Odznaki',
      iconKey: 'badges',
      onClick: toggleSidebar,
    },
  ];

  return (
    <div
      className={`fixed inset-y-0 left-0 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 lg:relative w-80 lg:w-96 h-screen flex flex-col justify-between bg-white shadow-custom z-50 transition-transform duration-300 ease-in-out`}
    >
      {/* Górna sekcja: dane użytkownika */}
      <div>
        <Link
          to="/account/user"
          className="flex items-center px-16 py-14 text-[#016623] hover:bg-gray-100"
          style={{ borderBottom: "1px solid #016622" }}
          onClick={toggleSidebar}
        >
          <img
            src={profileImageUrl}
            alt="User"
            className="w-16 h-16 lg:w-20 lg:h-20 rounded-full mr-7 object-cover"
          />
          <span>
            <strong className="text-base lg:text-lg font-semibold">
              {firstName} {lastName}
            </strong>
            <p className="text-xs lg:text-sm m-0">{cityName || 'Unknown City'}</p>
          </span>
        </Link>

        {/* Sekcja nawigacji */}
        <nav className="px-16 py-14">
          <ul className="space-y-2 mx-0 px-0">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.to}
                  className="flex items-center text-[#454545] font-semibold hover:bg-gray-300 p-2 rounded-md"
                  onClick={item.onClick}
                >
                  {item.iconKey ? (
                    <Icon {...iconMapping[item.iconKey]} />
                  ) : (
                    item.icon
                  )}
                  {item.label}
                </Link>
              </li>
            ))}

            {/* Wylogowanie jako osobny przycisk */}
            <li>
              <button
                onClick={handleLogout}
                className="flex items-center w-full text-[#454545] text-base font-semibold hover:bg-gray-300 p-2 rounded-md"
              >
                <Icon {...iconMapping.logout} />
                Wyloguj
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Dolna sekcja: logo */}
      <div className="mt-auto text-center pb-6">
        <img src="/images/user_account/logo.svg" alt="Logo" className="w-40 mx-auto" />
      </div>
    </div>
  );
}

export default Sidebar;
