import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import apolloClient from '../../../queries/ApolloUser';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserContext } from '../components/UserContext';
import { GET_CHALLENGE_DETAILS } from '../../../queries/GraphQL_Query';
import { Link } from 'react-router-dom';
import Ranking from './Ranking';

const CompetitionDetailView = () => {
  const { id } = useParams();
  const { userId } = useUserContext();
  const [activeTab, setActiveTab] = useState('informacje');
  const navigate = useNavigate();

  // Fetch competition info data
  const { data: infoData, loading: infoLoading, error: infoError } = useQuery(GET_CHALLENGE_DETAILS, {
    client: apolloClient,
    variables: { id: parseInt(id), userId },
  });

  useEffect(() => {
    if (infoData) console.log("Competition Info Data:", infoData);
    if (infoError) console.error("Info Error:", infoError);
  }, [infoData, infoError]);

  // const handleTabChange = (tab) => setActiveTab(tab);

  if (infoLoading) return <p>Ładowanie danych...</p>;
  if (infoError) return <p>Błąd ładowania danych: {infoError.message}</p>;

  const competitionInfo = infoData?.allActivitiesCompetitions.nodes[0];

  return (
    <div className="p-4">
      <button onClick={() => navigate(-1)} className="flex items-center text-primary-dark text-xl font-bold mb-4 bg-white cursor-pointer">
        <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_return.svg`} alt="Arrow Down Icon" className="pr-2 w-4" />
        <span className=''>Powrót do rywalizacji</span>
      </button>
     
     <div className='flex items-center justify-between'>
      <h1 className="text-primary-dark text-2xl font-bold">{competitionInfo?.name}</h1>

      <Link to={`/account/ranking/${id}`} className='flex items-center shadow-custom rounded-full py-4 px-8'>
        <img src={`${process.env.PUBLIC_URL}/images/user_account/menu_podium.svg`} alt="Podium" className="mr-4 w-8" />
        <span className='uppercase font-semibold text-xl text-secondary'>Pokaż ranking</span>
      </Link>
     </div>

      {/* Conditional rendering based on active tab */}
        <div>
          <p className="text-btn_primary text-lg mt-2 inline">{new Date(competitionInfo.start).toLocaleDateString()}</p>
          <p className='px-2 inline'>-</p>
          <p className="text-btn_primary text-lg mt-2 inline">{new Date(competitionInfo.end).toLocaleDateString()}</p>
          {competitionInfo.activitiesPhotosByCompetitionId.nodes[0]?.image && (
            <div className='w-[880px]'>
              <img
              src={`http://panel.aktywne.miasta.pl/media/${competitionInfo.activitiesPhotosByCompetitionId.nodes[0].image}`}
              alt={competitionInfo.name}
              className="h-100 w-full rounded-3xl shadow mt-4"
            />
            </div>
          )}
          <p className="text-lg block" style={{paddingTop: "20px !important"}}>{competitionInfo.description}</p>
        </div>
        {activeTab === 'ranking' && competitionInfo?.id && (
  <Ranking competitionId={parseInt(competitionInfo.id, 10)} />
)}



    </div>
  );
};

export default CompetitionDetailView;
