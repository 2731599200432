import React, { useState, useEffect } from "react";
import { SPORTS_URL } from '../../../queries/api';
import sportIcons from "../components/Icons"; // Import ikon sportów

const CreateGoal = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [disciplines, setDisciplines] = useState([]);
    const [selectedDisciplines, setSelectedDisciplines] = useState({});
    const [isOpenGoal, setIsOpenGoal] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState();
    const [selectedTarget, setSelectedTartget] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pobierz listę dyscyplin
    useEffect(() => {
        const fetchDisciplines = async () => {
            try {
                const response = await fetch(SPORTS_URL);
                if (!response.ok) throw new Error("Błąd podczas pobierania dyscyplin");
                const data = await response.json();
                setDisciplines(data.results);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchDisciplines();
    }, []);

    // Funkcja do obsługi zmiany wyboru dyscypliny
    const handleSelect = (sportId) => {
        setSelectedDisciplines((prevSelected) => ({
            ...prevSelected,
            [sportId]: !prevSelected[sportId],
        }));
    };

    const handleConfirmGoal = () => {
        if (!selectedGoal) {
            alert("Wybierz cel przed zatwierdzeniem!");
            return;
        }
        console.log("Wybrano cel:", selectedGoal);
        setIsOpenGoal(false); // Zamknij okno wyboru
    };

    const selectedDisciplinesList = Object.keys(selectedDisciplines)
    .filter((id) => selectedDisciplines[id]) // Filtrujemy tylko wybrane
    .map((id) => {
        const sport = disciplines.find((sport) => sport.id === parseInt(id));
        if (!sport) return null; // Jeśli sport nie istnieje, pomijamy

        return {
            name: sport.name,
            icon: sportIcons[sport.id] || sportIcons[sport.name], // Pobieramy ikonę po ID lub nazwie
        };
    })
    .filter(Boolean); // Usuwamy `null` wartości
    
    return (
        <div className="max-w-6xl mx-auto p-6">
            <div className="flex flex-col items-center w-full space-y-4 box-border">
                {/* Przycisk wyboru celu */}
                <button onClick={() => setIsOpenGoal(!isOpenGoal)} className="block text-primary-dark font-semibold uppercase text-center text-xl shadow-custom p-4 w-1/2 rounded-full">
                    Wybierz rodzaj celu
                </button>

                <div className="relative w-full">
                    {isOpenGoal && (
                        <div className="absolute w-1/4 mx-0 top-4 left-1/2 -translate-x-1/2 bg-white border shadow-custom rounded-xl p-8 z-50">
                            <h3 className="text-xl font-semibold text-primary-dark mb-6 text-center">Wybierz cel:</h3>

                            {/* Lista celów */}
                            <div className="flex flex-col space-y-4">
                                {["Dystans", "Kalorie", "Czas"].map((goal, index) => (
                                    <label key={index} className="flex items-center space-x-3 cursor-pointer box-border">
                                        {/* Input radio */}
                                        <input 
                                            type="radio" 
                                            name="goalType" 
                                            value={goal} 
                                            checked={selectedGoal === goal} 
                                            onChange={() => setSelectedGoal(goal)} // Obsługa zmiany
                                            className="hidden box-border" 
                                        />

                                        {/* Stylizowane radio */}
                                        <div className={`w-6 h-6 border border-primary-dark rounded-md flex items-center justify-center ${
                                                selectedGoal === goal ? "bg-primary-green border-green-600" : ""
                                            }`} style={{border: "1px solid #69b440"}}
                                        >
                                        {selectedGoal === goal && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white bg-blue" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" />
                                            </svg>
                                        )}
                                        </div>

                                        {/* Tekst opcji */}
                                        <span className="text-gray-700 font-medium">{goal}</span>
                                    </label>
                                ))}
                            </div>

                            {/* Przycisk potwierdzający wybór */}
                            <button
                                onClick={handleConfirmGoal}
                                className="mt-6 w-full bg-green-600 text-white py-3 px-4 rounded-full hover:bg-green-500 transition-colors"
                            >
                                Potwierdź
                            </button>
                        </div>
                    )}
                </div>


                {/* Pole na wartość celu */}
                <input 
                    type="text" 
                    value={selectedTarget}
                    className="appearance-none border box-border w-1/2 !border-secondary outline-none text-secondary font-semibold text-center placeholder:text-secondary text-xl p-4 rounded-full focus:!border-primary-green" 
                    placeholder="Dystans"
                    onChange={(e) => setSelectedTartget(e.target.value)} // Obsługa zmiany
                />

                {/* Przycisk wyboru aktywności */}
                <button onClick={() => setIsOpen(!isOpen)} className="block text-white font-semibold uppercase text-center text-xl bg-btn_primary shadow-custom p-4 w-1/2 rounded-full">
                    Wybierz aktywności
                </button>

                {/* Przycisk otwierający listę dyscyplin */}
                <div className="relative w-full">
                    {/* Modal z listą dyscyplin */}
                    {isOpen && (
                        <div className="absolute top-4 left-0 right-0 bg-white border shadow-custom rounded-xl p-8 z-50">
                            <h3 className="text-xl font-semibold text-primary-dark mb-8">Wybierz dyscypliny:</h3>

                            {loading ? (
                                <p className="text-gray-500">Ładowanie...</p>
                            ) : error ? (
                                <p className="text-red-500">{error}</p>
                            ) : (
                                <div className="grid grid-cols-3 gap-4">
                                    {disciplines.map((sport) => {
                                        const icon = sportIcons[sport.id] || sportIcons[sport.name]; // Pobiera ikonę po ID lub nazwie
                                        return (
                                            <label key={sport.id} className="flex items-center cursor-pointer space-x-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedDisciplines[sport.id] || false}
                                                    onChange={() => handleSelect(sport.id)}
                                                    className="hidden"
                                                />

                                                {/* Ikonka sportu */}
                                                {icon && (
                                                    <img 
                                                        src={icon} 
                                                        alt={sport.name} 
                                                        className="w-10 h-10"
                                                    />
                                                )}

                                                {/* Checkbox stylizowany */}
                                                <span
                                                    className={`w-6 h-6 border-solid border-gray-300 rounded-md flex items-center justify-center ${
                                                        selectedDisciplines[sport.id] ? "bg-[#72BF44] border-green-600" : "bg-white"
                                                    }`}
                                                >
                                                    {selectedDisciplines[sport.id] && (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-4 w-4 text-white"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                                <span>{sport.name}</span>
                                            </label>
                                        );
                                    })}
                                </div>
                            )}

                            {/* Zamknięcie listy */}
                            <button
                                onClick={() => setIsOpen(false)}
                                className="mt-8 w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                            >
                                Zamknij
                            </button>
                        </div>
                    )}
                </div>

                {/* Przełącznik z opisem */}
                <label className="flex items-center cursor-pointer space-x-3">
                    <input 
                        type="checkbox" 
                        className="sr-only peer" 
                        checked={isChecked} 
                        onChange={() => setIsChecked(!isChecked)}
                    />
                    <div className="relative w-20 h-10 bg-gray-300 rounded-full peer-checked:bg-primary-dark after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:border after:rounded-full after:h-8 after:w-8 after:transition-all peer-checked:after:translate-x-10"></div>
                    <span className="text-[#454545] text-lg font-medium">
                        Cel zaliczony musi być podczas jednej aktywności
                    </span>
                </label>
                
                
                <strong className="text-lg">Cel: {selectedGoal}</strong>
                <strong className="text-lg">Dystans: {selectedTarget}</strong>
                  {/* Lista wybranych dyscyplin */}
                  {selectedDisciplinesList.length > 0 && (
                        <div className="mt-4 flex items-center justify-center gap-5">
                            <strong className="text-lg">Aktywności: </strong>
                            <div className="flex flex-col items-start">
                            {selectedDisciplinesList.map((discipline, index) => (
                                <div key={index} className="flex items-center justify-center">
                                    {discipline.icon && (
                                        <img src={discipline.icon} alt={discipline.name} className="w-12 h-12 mr-2"></img>
                                    )}
                                    <p className="text-lg">{discipline.name}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                    )}

                <button className="bg-primary-dark uppercase font-bold text-lg text-white px-8 py-3 rounded-full">
                    Potwierdź
                </button>
            </div>
        </div>
    );
};

export default CreateGoal;
