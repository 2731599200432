import React from 'react';

const AuthLayout = ({ children , loading}) => {
  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Lewa sekcja - obrazek */}
      <div className="w-1/3 flex items-center justify-center bg-[#DFF1D5] z-10 shadow-custom">
        <img src="/images/user_account/running_men.svg" alt="Obrazek" className="fixed w-1/12 top-1/4 left-20 h-auto" />
        <img src="/images/user_account/cycling_men.svg" alt="Obrazek" className="fixed w-1/5 top-1/2 left-72 h-auto" />
      </div>

      {/* Prawa sekcja - logo + formularz */}
      <div className="w-2/3 flex relative flex-col items-center justify-center px-12 z-9">
        {/* Logo */}
        {!loading && (
          <div className="py-10 flex flex-col">
          <img src="/images/user_account/logo.svg" alt="Logo" className="w-80" />
          {/* <img 
            src="/images/user_account/logo.svg" 
            alt="Logo Reflection" 
            className="w-80 transform scale-y-[-1] opacity-40"
            style={{ maskImage: "linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0))", WebkitMaskImage: "linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0))" }}
          /> */}
        </div>
        )}

        {/* Formularz (dzieci) */}
        <div className="w-3/4 bg-white">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
