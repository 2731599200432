import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import apolloClient from '../../../queries/ApolloUser';
import { GET_USER_GPS, GET_USER_ACTIVITY_DETAIL } from '../../../queries/GraphQL_Query';
import { MapContainer, TileLayer, Polyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Component to fit bounds based on GPS data
const FitBoundsMap = ({ gpsData }) => {
  const map = useMap();

  useEffect(() => {
    if (gpsData.length > 0) {
      const bounds = gpsData.map(gps => [gps.latitude, gps.longitude]);
      map.fitBounds(bounds, { padding: [50, 50] }); // Add padding for better view
    }
  }, [gpsData, map]);

  return null;
};

const ActivityDetail = () => {
  const { activityId } = useParams();
  const userId = parseInt(localStorage.getItem('userId'), 10);
  const [gpsData, setGpsData] = useState([]);
  const [activityDetails, setActivityDetails] = useState(null);

  const [fetchGpsData, { loading: loadingGps }] = useLazyQuery(GET_USER_GPS, {
    client: apolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log("Fetched GPS Data:", data);
      if (data?.allActivitiesActivitygps?.nodes) {
        setGpsData(data.allActivitiesActivitygps.nodes);
      }
    },
  });

  const [fetchActivityDetails, { loading: loadingActivity }] = useLazyQuery(GET_USER_ACTIVITY_DETAIL, {
    client: apolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log("Fetched Activity Details:", data);
      if (data?.allActivitiesActivityindividuals?.nodes.length) {
        setActivityDetails(data.allActivitiesActivityindividuals.nodes[0]);
      }
    },
  });

  useEffect(() => {
    const activityIdInt = parseInt(activityId, 10);
    if (activityIdInt && userId) {
      fetchGpsData({ variables: { in: [activityIdInt] } });
      fetchActivityDetails({ variables: { activityId: activityIdInt, userId } });
    }
  }, [activityId, userId, fetchGpsData, fetchActivityDetails]);

  if (loadingActivity || loadingGps) return <p>Ładowanie szczegółów aktywności...</p>;
  if (!activityDetails) return <p>Nie znaleziono szczegółów aktywności.</p>;

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-primary-dark uppercase">Szczegóły Aktywności</h2>

      {/* Informacje o treningu */}
      <div className="bg-white mt-6 mb-6">
      <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="p-4 bg-white rounded-xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_run.svg`} alt="Liczba aktywności" className="w-12 h-12 mb-2" />
              <h3 className="text-lg font-bold">Data</h3>
              <p className="text-4xl font-bold mt-2">{new Date(activityDetails.beginDate).toLocaleString()}</p>
            </div>
            <div className="p-4 bg-white rounded-xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_timer-outline.svg`} alt="Łączny czas" className="w-12 h-12 mb-2" />
              <h3 className="text-lg font-bold">Czas trwania</h3>
              <p className="text-4xl font-bold mt-2">{activityDetails.endTime} minut</p>
            </div>
            <div className="p-4 bg-white rounded-xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_cupcake.svg`} alt="Spalone kalorie" className="w-12 h-12 mb-2" />
              <h3 className="text-lg font-bold">Spalone kalorie</h3>
              <p className="text-4xl font-bold mt-2">{activityDetails.endCalories} kcal</p>
            </div>
            <div className="p-4 bg-white rounded-xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_map-marker-distance.svg`} alt="Przebyty dystans" className="w-12 h-12 mb-2" />
              <h3 className="text-lg font-bold">Przebyty dystans</h3>
              <p className="text-4xl font-bold mt-2">{activityDetails.endDistance.toFixed(2).replace('.', ',')} km</p>
            </div>
            <div className="p-4 bg-white rounded-xl shadow-custom text-center flex flex-col items-center">
              <img src={`${process.env.PUBLIC_URL}/images/user_account/icon_map-marker-distance.svg`} alt="Przebyty dystans" className="w-12 h-12 mb-2" />
              <h3 className="text-lg font-bold">Średnia prędkość</h3>
              <p className="text-4xl font-bold mt-2">{activityDetails.endSpeed.toFixed(2).replace('.', ',')} km/h</p>
            </div>
          </div>
      </div>

      <h3 className="text-xl font-bold text-primary-dark">Mapa trasy</h3>
      {gpsData.length > 0 ? (
        <MapContainer
          center={[gpsData[0]?.latitude || 0, gpsData[0]?.longitude || 0]}
          zoom={13}
          style={{ height: '400px', width: '100%' }}
          className="my-6 rounded-xl shadow-custom z-10 relative"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Polyline
            positions={gpsData.map(gps => [gps.latitude, gps.longitude])}
            color="blue"
          />
          <FitBoundsMap gpsData={gpsData} />
        </MapContainer>
      ) : (
        <p>Brak danych GPS do wyświetlenia trasy.</p>
      )}
    </div>
  );
};

export default ActivityDetail;
