import React, { useState, useEffect } from 'react';
import { useUserContext } from '../components/UserContext';
import { SPORTS_URL, BASE_IMAGE_URL, CITIES_URL } from "../../../queries/api";

const UserForm = () => {
  const {
    userId,
    firstName,
    lastName,
    email,
    dateOfBirth,
    height,
    weight,
    cityId,
    preferredSports,
    sex,
    profileImage,
    showLocationToFriends,
  } = useUserContext();

  const [userData, setUserData] = useState({
    userId: userId || '',
    name: firstName || '',
    surname: lastName || '',
    email: email || '',
    height: height || '',
    weight: weight || '',
    birthDate: dateOfBirth || '1 JAN 2000',
    gender: sex === '0' ? 'Kobieta' : 'Mężczyzna',
    city: '', // Will fetch city name based on cityId
    sports: {},
    acceptTerms: true, // Assume terms accepted based on context or localStorage
    allowMarketing: true,
    allowLocation: showLocationToFriends || false,
  });

  const [isCityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [sports, setSports] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingSports, setLoadingSports] = useState(true);
  const [cityError, setCityError] = useState(null);
  const [sportsError, setSportsError] = useState(null);
  const [nextCitiesUrl, setNextCitiesUrl] = useState(null);
  const [loadingMoreCities, setLoadingMoreCities] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userData);

  const handleGenderChange = (gender) => {
    setUserData((prevData) => ({
      ...prevData,
      gender,
    }));
    setSelectedGender(gender);
  };

  useEffect(() => {
    setUserData((prevData) => ({
      ...prevData,
      userId,
      name: firstName || '',
      surname: lastName || '',
      email: email || '',
      height: height || '',
      weight: weight || '',
      birthDate: dateOfBirth || '1 JAN 2000',
      gender: sex === '0' ? 'Kobieta' : 'Mężczyzna',
      city: cityId ? cities.find((c) => c.id === cityId)?.name || '' : '',
      sports: preferredSports.reduce((acc, sport) => ({ ...acc, [sport]: true }), {}),
      acceptTerms: true,
      allowMarketing: true,
      allowLocation: showLocationToFriends || false,
    }));
  }, [firstName, lastName, email, dateOfBirth, height, weight, cityId, preferredSports, sex, showLocationToFriends, cities]);

  // const BASE_IMAGE_URL = "http://146.59.55.173/media/uploads/";
      // Generowanie pełnego URL zdjęcia profilowego
      const profileImageUrl = profileImage
      ? `${BASE_IMAGE_URL}${profileImage}`
      : 'https://via.placeholder.com/100';

  // Fetch cities on mount
  useEffect(() => {
    const fetchInitialCities = async () => {
      if (!nextCitiesUrl) return; // Prevents fetching with an invalid URL
      setLoadingCities(true);
      try {
        const response = await fetch(nextCitiesUrl);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Błąd pobierania miast: ${response.status}, ${errorText}`);
        }
        const data = await response.json();
        setCities(data.results);
        setNextCitiesUrl(data.next);
      } catch (error) {
        setCityError('Nie udało się pobrać listy miast.');
      } finally {
        setLoadingCities(false);
      }
    };

    fetchInitialCities();
  }, []);
    
       // Load more cities when "Załaduj więcej..." is clicked
  const fetchMoreCities = async () => {
    if (!nextCitiesUrl || loadingMoreCities) return;
    setLoadingMoreCities(true);
    try {
      const response = await fetch(nextCitiesUrl);
      if (!response.ok) throw new Error('Błąd pobierania kolejnych miast');
      const data = await response.json();
      setCities((prev) => [...prev, ...data.results]);
      setNextCitiesUrl(data.next);
    } catch (error) {
      setCityError('Nie udało się pobrać kolejnych miast.');
    } finally {
      setLoadingMoreCities(false);
    }
  };

  const fetchSports = async () => {
    setLoadingSports(true);
    try {
      const response = await fetch(SPORTS_URL);
      if (!response.ok) throw new Error('Error fetching sports');
      const data = await response.json();
      setSports(data.results);
    } catch (error) {
      setSportsError('Could not load sports');
      console.error(error);
    } finally {
      setLoadingSports(false);
    }
  };

  useEffect(() => {
    fetchSports();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSportsChange = (sportId) => {
    setUserData((prevData) => ({
      ...prevData,
      sports: { ...prevData.sports, [sportId]: !prevData.sports[sportId] },
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleCityChange = (city) => {
    setUserData((prevData) => ({
      ...prevData,
      city: city.name,
    }));
    setCityDropdownOpen(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownOpen(!isCityDropdownOpen);
  };

  return (
    <div className="p-6 flex flex-col justify-center items-center md:flex-row md:justify-evenly md:items-start=">
      <div className="w-2/5 pr-8 box-border">
        <h2 className="text-2xl font-bold mb-4 pb-10 text-primary-dark uppercase">Dane użytkownika</h2>
        <div className="flex items-center justify-center w-full">
          <img
            src={profileImageUrl || "https://via.placeholder.com/100"}
            alt="User"
            className="w-28 h-28 rounded-full"
          />
        </div>
        <form className="space-y-4 w-full p-0 m-0">
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Imię</label>
            <input
              type="text"
              name="name"
              value={userData.name}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
            />
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Nazwisko</label>
            <input
              type="text"
              name="surname"
              value={userData.surname}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
            />
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Mail</label>
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
            />
          </div>
          <div className='flex flex-col'>
            <p className='text-xs text-[#B7B7B7] w-full pl-3'>
            Wzrost, waga, data urodzenia i płeć są niezbędne do prawidłowego obliczenia spalanych przez ciebie kalorii. Prosimy o ich podanie, aby aplikacja mogła działać prawidłowo.
            </p>
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Wzrost</label>
            <input
              type="number"
              name="height"
              value={userData.height}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
            />
          </div>
          <div className='flex flex-col'>
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Waga</label>
            <input
              type="number"
              name="weight"
              value={userData.weight}
              onChange={handleChange}
              className="border p-2 rounded-3xl h-4"
            />
          </div>
          <div className="mb-4 flex items-center justify-between py-4">
            <label className="block text-secondary font-semibold">Data urodzenia</label>
            <div className="flex items-center bg-gray-100 rounded-full p-2 w-40 justify-center">
              <span className="text-gray-500">{userData.birthDate}</span>
            </div>
          </div>
          <p className="text-sm text-gray-500 mb-4">
            Rekomendujemy wybranie swojego miasta.
          </p>
          <div className="mb-6">
            <label className="pl-2 inline-block pb-2 text-secondary font-semibold">Miasto</label>
            <button
              type="button"
              onClick={() => setCityDropdownOpen(!isCityDropdownOpen)}
              className="w-full border p-2 rounded-3xl text-center bg-gradient-to-r from-[#72BF44] to-[#003C10] text-white h-10 flex items-center justify-center"
            >
              <span className="ml-2">&#9660;</span>
              {userData.city || 'Wybierz miasto'}
            </button>
            {isCityDropdownOpen && (
          <ul className="absolute left-0 right-0 mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
            {loadingCities ? (
              <li className="px-4 py-2 text-gray-500">Ładowanie miast...</li>
            ) : cityError ? (
              <li className="px-4 py-2 text-red-500">{cityError}</li>
            ) : cities.length === 0 ? (
              <li className="px-4 py-2 text-gray-500">Brak dostępnych miast</li>
            ) : (
              cities.map((city, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => handleCityChange(city)}
                >
                  {city.name}
                </li>
              ))
            )}

            {loadingMoreCities ? (
              <li className="px-4 py-2 text-gray-500">Ładowanie...</li>
            ) : nextCitiesUrl ? (
              <li
                className="px-4 py-2 text-blue-500 cursor-pointer hover:bg-gray-100"
                onClick={fetchMoreCities}
              >
                Załaduj więcej...
              </li>
            ) : null}
          </ul>
        )}
          </div>

          {/* Płeć */}
          <div className="mb-6">
            <label className="block text-secondary font-semibold mb-2">Płeć</label>
            <div className="flex space-x-4">
            <button
                className={`px-4 py-2 rounded-full border transition-colors duration-300 ${
                  userData.gender === 'Kobieta' ? 'bg-[#72BF44] text-white' : 'bg-white shadow-custom text-gray-700' }`}
                onClick={(e) => { e.preventDefault(); handleGenderChange('Kobieta'); }}
              >
                Kobieta
              </button>
              <button
                className={`px-4 py-2 rounded-full border transition-colors duration-300 ${
                  selectedGender === 'Mężczyzna' ? 'bg-[#72BF44] text-white' : 'bg-white shadow-custom text-gray-700'
                }`}
                onClick={(e) => { e.preventDefault(); handleGenderChange('Mężczyzna'); }}
              >
                Mężczyzna
              </button>
            </div>
          </div>

          {/* Kod QR */}
          <div className="mt-6 flex flex-col items-center">
            <img
              src="https://via.placeholder.com/100"
              alt="QR Code"
              className="w-20 h-20 rounded-full mb-2"
            />
            <p className="text-xs text-gray-500">ID: {userData.userId}</p>
          </div>
        </form>
      </div>

            {/* Right Side - Sports Interests */}
      <div className="w-2/5 pt-20">
        <p className="text-sm text-gray-500 mb-4 inline-block">
          Wybierz sport, który Cię interesuje, aby otrzymywać informacje o rywalizacjach, konkursach oraz grach miejskich dostępnych dla danej kategorii.
        </p>
        {/* Sports Grid */}
        <div className="grid grid-cols-3 gap-4 mb-6 mt-6">
        {loadingSports ? (
          <p className="text-gray-500">Ładowanie sportów...</p>
        ) : sportsError ? (
          <p className="text-red-500">{sportsError}</p>
        ) : (
          sports.map((sport) => (
            <label key={sport.id} className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              name={sport.name.toLowerCase()}
              checked={userData.sports[sport.id] || false}
              onChange={() => handleSportsChange(sport.id)}
              className="hidden"
            />
            <span
              className={`w-6 h-6 border-solid mr-2 border-gray-300 rounded-md flex items-center justify-center ${
                userData.sports[sport.id] ? 'bg-[#72BF44] border-green-600' : 'bg-white'
              }`}
            >
              {userData.sports[sport.id] && (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" />
                </svg>
              )}
            </span>
            {sport.name}
          </label>
        ))
        )}
        </div>
        {/* Toggle Options */}
        <div className="space-y-4">
        {/* Accept Terms */}
        <div className="flex items-center">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={userData.acceptTerms}
              onChange={handleToggleChange}
              className="sr-only peer"
            />
            <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
            <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
          </label>
          <span>Akceptuję regulamin</span>
          </div>

              {/* Accept Competition Terms */}
              <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowMarketing"
                checked={userData.allowMarketing}
                onChange={handleToggleChange}
                className="sr-only peer"
                />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
                <span>Akceptuję regulamin rywalizacji</span>
          </div>
                  <span className='inline-block text-sm text-[#B7B7B7]'>Zaznacz tę opcję, jeśli chcesz aby Twoje imię i nazwisko było widoczne w rankingu rywalizacji i wyszukiwarce znajomych. Odznacz tę opcję, jeśli chcesz, aby Twoje dan były  ukryte dla wszystkich użytkowników aplikacji.</span>

          {/* Allow Display of Personal Data */}
          <div className="flex items-center justify-between">
            <span>Zezwalam na wyświetlanie moich danych osobowych innym użytkownikom</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#1F5F1E] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
          </div>

          {/* Allow Friends to See Location */}
          <div className="flex items-center justify-between">
            <span>Pozwól znajomym widzieć moją lokalizację</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#1F5F1E] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 w-4/5 mx-auto space-y-2">
          <button className="bg-[#1F5F1E] text-white py-3 px-4 rounded-full w-full hover:bg-primary-green transition-colors">
            Akceptuję
          </button>
          <button className="bg-white shadow-custom text-gray-800 py-3 px-4 rounded-full w-full hover:bg-gray-300 transition-colors">
            Zmień hasło
          </button>
          <button className="bg-white shadow-custom text-red-500 py-3 px-4 rounded-full w-full hover:bg-red-500 hover:text-white transition-colors">
            Usuń konto
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
