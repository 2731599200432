// src/components/GoogleLogin.js
import { useGoogleLogin } from '@react-oauth/google';
import { FaGoogle } from "react-icons/fa";

const GoogleLoginButton = ({ onSuccess, onError }) => {
  const login = useGoogleLogin({ onSuccess, onError });

  return (
    <button
      onClick={() => login()}
      className="w-full py-2 bg-red-500 text-white text-base font-semibold rounded-full hover:bg-red-700 transition duration-200 flex justify-center items-center"
    >
      <FaGoogle className="mr-2 text-lg" />
      Zaloguj przez Google
    </button>
  );
};

export default GoogleLoginButton;
