const sportIcons = {
    1: require("../../../assets/icons/userAccount/am_bieganie.png"),
    2: require("../../../assets/icons/userAccount/am_rower.png"),
    3: require("../../../assets/icons/userAccount/am_nordic.png"),
    4: require("../../../assets/icons/userAccount/am_chodzenie.png"),
    5: require("../../../assets/icons/userAccount/am_rolki.png"),
    6: require("../../../assets/icons/userAccount/am_kajakarstwo.png"),
    7: require("../../../assets/icons/userAccount/am_kolarstwo.png"),
    8: require("../../../assets/icons/userAccount/am_narty.png"),
    9: require("../../../assets/icons/userAccount/am_snowboard.png"),
    10 : require("../../../assets/icons/userAccount/am_plywanie.png"),
    12: require("../../../assets/icons/userAccount/am_canicross.png"),
    13: require("../../../assets/icons/userAccount/am_deskorolka.png"),
    14: require("../../../assets/icons/userAccount/am_hulajnoga.png"),
    15: require("../../../assets/icons/userAccount/am_jazda_konna.png"),
    16: require("../../../assets/icons/userAccount/am_jazda_na_wozku.png"),
    17: require("../../../assets/icons/userAccount/am_lyzwy.png"),
    18: require("../../../assets/icons/userAccount/am_nartorolki.png"),
    19: require("../../../assets/icons/userAccount/am_wioslarstwo.png"),
  };
  
  export default sportIcons;
  